import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BadgeStyled, BadgeType } from './Badge.style';

interface BadgePropsType {
    children: React.ReactNode;
    type?: BadgeType;
    clickable?: boolean;
}

export const Badge = observer('Badge', ({ children, type = 'primary', clickable = false }: BadgePropsType) => (
    <BadgeStyled
        type={type}
        clickable={clickable}
    >
        {children}
    </BadgeStyled>
));
