import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { makeObservable } from 'src_common/common/mobx-wrapper';
import { Common } from 'src/domains/common/Common';
import { Logger } from 'src/domains/common/Logger';

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        carma: {
            roi: {
                init(url: string, flag: boolean): void;
                register(amount: string | number): void;
            };
        };
    }
}

// SymplifyState carma is a third part library which is used to track user from email campaigns.
// https://help.symplify.com/hc/en-us/articles/360011237219-Conversion-tracking
export class SymplifyState {
    public static get = AutoWeakMap.create((_common: Common) => new SymplifyState());

    private constructor() {
        makeObservable(this);
    }

    public initCarmaRoi = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window === undefined) {
            return;
        }
        try {
            window.carma.roi.init('https://eu-proxy-3.symplifymail.com/mail', true);
        } catch (error) {
            Logger.captureError('Error in initCarmaRoi', 'internal', { error });
        }
    };

    public registerCarmaConversion = (amount: number): void => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window === undefined) {
            return;
        }
        try {
            window.carma.roi.register(amount);
        } catch (error) {
            Logger.captureError('Error in registerCarmaConversion', 'internal', { error });
        }
    };
}
