import { computed, makeObservable } from 'mobx';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { TrpcClient } from 'src/appState/TrpcClient';
import { getRollingLimits, postSubmitDepositLimits } from 'src_server/trpc/types/responsibleGambling';
import { UsersState } from 'src/domains/players/state/UsersState';

class CurrentTime {
    public connect(value: MobxValue<DateTime>): NodeJS.Timeout {
        return setInterval(() => {
            value.setValue(DateTime.current());
        }, 1000);
    }

    public dispose(interval: NodeJS.Timeout): void {
        clearInterval(interval);
    }

    public static create(): MobxValue<DateTime> {
        return MobxValue.create({
            initValue: DateTime.current(),
            connect: new CurrentTime(),
        });
    }
}

export class DepositLimitsPopupItemState {
    public constructor(
        public readonly period: 1 | 7 | 30,
        private readonly currentTime: MobxValue<DateTime>,
        private readonly configComponents: ConfigComponents,
        private readonly trpcClient: TrpcClient,
        private readonly usersState: UsersState,
        public readonly getLimits: () => getRollingLimits.LimitsObjectSchemaType | undefined | null
    ) {
        makeObservable(this);
    }

    @computed public get active(): string | undefined {
        const activeOldValue = this.getLimits()?.activeLimitValue;

        if (activeOldValue === undefined) {
            return activeOldValue;
        }

        return this.configComponents.precision.newFromOld(activeOldValue).format(this.usersState.currency);
    }

    @computed
    public get returnActiveState(): FormInputState<string, string> {
        const limits = this.getLimits();
        const currLimitOldFormat = limits?.pendingLimitValue ?? limits?.activeLimitValue;

        const currLimit =
            currLimitOldFormat === undefined
                ? undefined
                : this.configComponents.precision.newFromOld(currLimitOldFormat).format(this.usersState.currency);

        return FormInputState.new(currLimit ?? '');
    }

    @computed public get pending(): string | undefined {
        const pendingOldValue = this.getLimits()?.pendingLimitValue ?? undefined;

        if (pendingOldValue === undefined) {
            return pendingOldValue;
        }

        return this.configComponents.precision.newFromOld(pendingOldValue).format(this.usersState.currency);
    }

    @computed public get limitExceededDeadline(): boolean {
        const lastUpdateDate = this.getLimits()?.pendingRequestedAt ?? undefined;
        if (lastUpdateDate === undefined) {
            return false;
        }

        const current = this.currentTime.getValue();
        const lastUpdate = DateTime.from(lastUpdateDate)?.addDays(1);

        return lastUpdate === undefined ? false : current.isAfter(lastUpdate);
    }

    public async onSubmit(decision: postSubmitDepositLimits.InputType['payload']['approvals']): Promise<void> {
        await this.trpcClient.client.responsibleGamblingRouter.postSubmitDepositLimits.mutate({
            payload: {
                approvals: decision,
            },
        });
    }
}

export class DepositLimitsPopupState {
    public readonly daily: DepositLimitsPopupItemState;
    public readonly weekly: DepositLimitsPopupItemState;
    public readonly monthly: DepositLimitsPopupItemState;

    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly configComponents: ConfigComponents,
        private readonly usersState: UsersState
    ) {
        makeObservable(this);
        const currentTime = CurrentTime.create();

        this.daily = new DepositLimitsPopupItemState(
            1,
            currentTime,
            this.configComponents,
            this.trpcClient,
            this.usersState,
            () => this.getRollingLimits().get(1)
        );

        this.weekly = new DepositLimitsPopupItemState(
            7,
            currentTime,
            this.configComponents,
            this.trpcClient,
            this.usersState,
            () => this.getRollingLimits().get(7)
        );

        this.monthly = new DepositLimitsPopupItemState(
            30,
            currentTime,
            this.configComponents,
            this.trpcClient,
            this.usersState,
            () => this.getRollingLimits().get(30)
        );
    }

    private getRollingLimits = (): Map<number, getRollingLimits.LimitsObjectSchemaType> => {
        const data = this.usersState.rollingNetDepositLimitData.valueReady;
        if (data === null) {
            return new Map();
        }
        return data;
    };

    @computed public get isActive(): boolean {
        return [this.daily, this.weekly, this.monthly].some((period) => period.limitExceededDeadline);
    }

    private async refreshLimits(): Promise<void> {
        await this.usersState.rollingNetDepositLimitData.refreshAndWait();
    }

    private generatePayload(action: 'approve' | 'reject'): postSubmitDepositLimits.InputType['payload']['approvals'] {
        const payload: postSubmitDepositLimits.InputType['payload']['approvals'] = [];

        if (this.daily.limitExceededDeadline) payload.push({ action, windowLengthInDays: this.daily.period });
        if (this.weekly.limitExceededDeadline) payload.push({ action, windowLengthInDays: this.weekly.period });
        if (this.monthly.limitExceededDeadline) payload.push({ action, windowLengthInDays: this.monthly.period });

        return payload;
    }

    private async handleSubmission(action: 'approve' | 'reject'): Promise<void> {
        const payload = this.generatePayload(action);

        try {
            await this.daily.onSubmit(payload);
        } catch (err) {
            console.error(err);
        } finally {
            await this.refreshLimits();
        }
    }

    public onReject = async (): Promise<void> => {
        await this.handleSubmission('reject');
    };

    public onAccept = async (): Promise<void> => {
        await this.handleSubmission('approve');
    };
}
