import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DepositLimitsPopupItemState,
    DepositLimitsPopupState,
} from 'src/domains/players/webview/components/RollingDepositLimitPopup/RollingDepositLimitPopup.state';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import {
    DepositLimitPopupBtn,
    DepositLimitPopupBtnWrapper,
    DepositLimitPopupDescription,
    DepositLimitPopupHeader,
    DepositLimitPopupInput,
    DepositLimitPopupPrevious,
} from './RollingDepositLimitPopup/RollingDepositLimitPopup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useCommon } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { assertNever } from 'src_common/common/assertNever';

const renderAfirmativeLabel = (
    languagesState: LanguagesState,
    depositLimitTime: 'daily' | 'weekly' | 'monthly',
    depositLimitsType: ConfigComponents['config']['depositLimitsType'],
    pendingValue: string = ''
): string => {
    if (depositLimitsType === 'calendarType') {
        if (depositLimitTime === 'daily') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.daily',
                'New Daily Limit: {active}',
                { active: pendingValue }
            );
        } else if (depositLimitTime === 'weekly') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.weekly',
                'New Weekly Limit: {active}',
                { active: pendingValue }
            );
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (depositLimitTime === 'monthly') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.monthly',
                'New Monthly Limit: {active}',
                { active: pendingValue }
            );
        }
    } else {
        if (depositLimitTime === 'daily') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.oneDay',
                'New 1 Day: {active}',
                { active: pendingValue }
            );
        } else if (depositLimitTime === 'weekly') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.sevenDays',
                'New 7 Days: {active}',
                { active: pendingValue }
            );
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (depositLimitTime === 'monthly') {
            return languagesState.getTranslation(
                'account.net-deposit-limit-popup.next-new-limit.label.thirtyDays',
                'New 30 Days: {active}',
                { active: pendingValue }
            );
        }
    }

    return assertNever('renderLabelTopUpLimitItem', depositLimitTime);
};
interface DepositLimitPopupItemPropsType {
    state: DepositLimitsPopupItemState;
    label: string;
    depositLimitTime: 'daily' | 'weekly' | 'monthly';
}

export const DepositLimitPopupItem = observer(
    'DepositLimitPopupItem',
    (props: DepositLimitPopupItemPropsType): JSX.Element | null => {
        const {
            appPlayersState: { usersState },
        } = useAppStateContext();
        const common = useCommon();
        const configComponents = ConfigComponents.get(common);
        const languagesState = LanguagesState.get(common);
        const { state, label, depositLimitTime } = props;
        const isNewLimit = state.pending !== undefined && state.limitExceededDeadline;

        return (
            <div>
                <DepositLimitPopupInput
                    type='text'
                    currency={usersState.moneySymbol}
                    maxLength={10}
                    label={
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.new-limit.label'
                            defaultText='{label}'
                            params={{ label }}
                        />
                    }
                    state={state.returnActiveState}
                    placeholder={state.active}
                    colorTheme='light'
                    isDisabled={true}
                    isNewLimit={isNewLimit}
                />
                {isNewLimit && (
                    <DepositLimitPopupPrevious>
                        {renderAfirmativeLabel(
                            languagesState,
                            depositLimitTime,
                            configComponents.config.depositLimitsType,
                            state.pending
                        )}
                    </DepositLimitPopupPrevious>
                )}
            </div>
        );
    }
);

export const RollingDepositLimitPopup = observer('DepositLimitPopup', (): JSX.Element | null => {
    const common = useCommon();
    const configComponents = ConfigComponents.get(common);
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();
    const languagesState = LanguagesState.get(common);

    const [state] = useState(() => new DepositLimitsPopupState(common.trpcClient, configComponents, usersState));
    const labelOneDay = languagesState.getTranslation('account.net-deposit-limit-popup.label.oneDay', '1 Day');
    const labelSevenDays = languagesState.getTranslation('account.net-deposit-limit-popup.label.sevenDays', '7 Days');
    const labelThirtyDays = languagesState.getTranslation(
        'account.net-deposit-limit-popup.label.thirtyDays',
        '30 Days'
    );
    const labelDaily = languagesState.getTranslation('account.net-deposit-limit-popup.label.daily', 'Daily Limit');
    const labelWeekly = languagesState.getTranslation('account.net-deposit-limit-popup.label.weekly', 'Weekly Limit');
    const labelMonthly = languagesState.getTranslation(
        'account.net-deposit-limit-popup.label.monthly',
        'Monthly Limit'
    );

    if (state.isActive === false) {
        return null;
    }

    return (
        <Popup
            title={
                <DepositLimitPopupHeader>
                    <I18n
                        langKey='account.rolling-net-deposit-limit-popup.title'
                        defaultText='Net deposit limits'
                    />
                </DepositLimitPopupHeader>
            }
            closeButton={false}
            maxWidth='400px'
        >
            <div>
                <DepositLimitPopupDescription>
                    <I18n
                        langKey='account.rolling-net-deposit-limit-popup.description'
                        defaultText='You made some changes to your net deposit limits, please accept before continuing.'
                    />
                </DepositLimitPopupDescription>

                <DepositLimitPopupItem
                    label={configComponents.config.depositLimitsType === 'calendarType' ? labelDaily : labelOneDay}
                    state={state.daily}
                    depositLimitTime='daily'
                />
                <DepositLimitPopupItem
                    label={configComponents.config.depositLimitsType === 'calendarType' ? labelWeekly : labelSevenDays}
                    state={state.weekly}
                    depositLimitTime='weekly'
                />
                <DepositLimitPopupItem
                    label={
                        configComponents.config.depositLimitsType === 'calendarType' ? labelMonthly : labelThirtyDays
                    }
                    state={state.monthly}
                    depositLimitTime='monthly'
                />
                <DepositLimitPopupBtnWrapper>
                    <DepositLimitPopupBtn
                        onClick={state.onAccept}
                        size='medium'
                    >
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.accept-button.label'
                            defaultText='Accept'
                        />
                    </DepositLimitPopupBtn>
                    <DepositLimitPopupBtn
                        onClick={state.onReject}
                        size='medium'
                        version='secondary-light'
                    >
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.reject-button.label'
                            defaultText='Revert Back'
                        />
                    </DepositLimitPopupBtn>
                </DepositLimitPopupBtnWrapper>
            </div>
        </Popup>
    );
});
