import { action, computed, makeObservable, observable } from 'mobx';
import { BonusStatusType, BonusViewType, BonusWinningsWalletViewType } from 'src/api_openapi/v2/bonus-engine/schemas';
import { Common } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { CreditsDrawerType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { BonusBalanceModel, BonusBalanceRewardType } from 'src/domains/players/state/BonusBalanceModel';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { DateTime } from 'src_common/utils/time/time';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { Amount } from 'src_common/common/amount/Amount';
import { z } from 'zod';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BadgeType } from 'src/domains/players/webview/components/Account/common/badge/Badge.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { GameSharedModel } from 'src/domains/casino/state/AppCasino.state';

const arrayCriteriaType = z.union([z.array(z.string()), z.array(z.number())]);
const primitiveCriteriaType = z.union([z.string(), z.number(), z.boolean()]);

export type ArrayCriteriaType = z.infer<typeof arrayCriteriaType>;
export type PrimitiveCriteriaType = z.infer<typeof primitiveCriteriaType>;

type CriteriaReturnType =
    | {
          isArray: true;
          data: ArrayCriteriaType;
      }
    | {
          isArray: false;
          data: PrimitiveCriteriaType;
      };

type BonusesReturnType =
    | {
          type: 'bet/spins';
          data: BonusViewType[] | null;
      }
    | {
          type: 'bonusBalance';
          data: BonusWinningsWalletViewType[] | null;
      };

interface FilterConditionsType {
    type: 'Min. Odds' | 'Bet Type' | 'Eligible Events' | 'Eligible Games';
    value: Array<string | number | boolean> | string | number | boolean;
    order: number;
}

export class CreditsState {
    public readonly bonusBalanceModel: BonusBalanceModel;
    private readonly basicDataModel: BasicDataModel;
    private readonly configComponents: ConfigComponents;
    public readonly languagesState: LanguagesState;
    private readonly starRouter: StarRouter;

    @observable public creditType: CreditsDrawerType;

    public static get = AutoWeakMap.create(
        (common: Common, creditsDrawerType: CreditsDrawerType) => new CreditsState(common, creditsDrawerType)
    );

    public constructor(
        private readonly common: Common,
        private readonly creditsDrawerType: CreditsDrawerType
    ) {
        this.bonusBalanceModel = BonusBalanceModel.get(this.common);
        this.languagesState = LanguagesState.get(this.common);
        this.basicDataModel = BasicDataModel.get(this.common);
        this.configComponents = ConfigComponents.get(this.common);
        this.starRouter = StarRouter.get(common);

        this.creditType = this.creditsDrawerType;
        makeObservable(this);
    }

    public get filters(): FiltersType[] {
        return [
            {
                key: 'free-bets',
                label: this.languagesState.getTranslation('account.credits.filter.free-bets', 'Free Bets'),
                onClick: () => this.handleChangeFilter('free-bets'),
            },
            {
                key: 'free-spins',
                label: this.languagesState.getTranslation('account.credits.filter.free-spins', 'Free Spins'),
                onClick: () => this.handleChangeFilter('free-spins'),
            },
            {
                key: 'bonus-balance',
                label: this.languagesState.getTranslation('account.credits.filter.bonus-balance', 'Bonus Balance'),
                onClick: () => this.handleChangeFilter('bonus-balance'),
            },
        ];
    }

    @action private handleChangeFilter(filterType: CreditsDrawerType): void {
        this.creditType = filterType;
        if (filterType === 'bonus-balance') {
            this.bonusBalanceModel.bonusWinningsBalances.refresh();
        } else {
            this.bonusBalanceModel.customerBonuses.refresh();
        }
    }

    public formatExpires(expiryAt: string, place: 'inline' | 'badge' = 'inline'): string {
        const formatedDate = DateTime.from(expiryAt);

        const date = formatedDate?.format('DD-MM-YYYY') ?? '';
        const time = formatedDate?.format('HH:mm') ?? '';
        if (place === 'badge') {
            return `${date} ${time}`;
        }

        return this.languagesState.getTranslation('account.credits.expires', 'Expires on {date} at {time}', {
            date,
            time,
        });
    }

    @computed public get bonuses(): BonusesReturnType | null {
        if (this.creditType === 'free-bets' || this.creditType === 'free-spins') {
            const balances = this.bonusBalanceModel.customerBonuses.valueReady;

            if (balances?.status === 200) {
                return { data: balances.body[this.mapCreditTypeToResponse] ?? null, type: 'bet/spins' };
            }

            return null;
        }

        const bonusBalance = this.bonusBalanceModel.bonusWinningsBalances.valueReady;

        if (bonusBalance?.status === 200) {
            return { data: bonusBalance.body, type: 'bonusBalance' };
        }

        return null;
    }

    @computed private get mapCreditTypeToResponse(): BonusBalanceRewardType {
        if (this.creditType === 'free-bets') {
            return BonusBalanceRewardType.FREEBET;
        }

        return BonusBalanceRewardType.FREESPIN;
    }

    public mapBadgeTypeBasedOnStatus(status: BonusStatusType): BadgeType {
        if (status === 'active') {
            return 'success';
        }

        if (status === 'canceled') {
            return 'warning';
        }

        if (status === 'expired') {
            return 'error';
        }

        return 'secondary';
    }

    public isButtonDisabled = (status: BonusStatusType): boolean => {
        const statusForDisabled = ['canceled', 'expired', 'claimed'];
        return statusForDisabled.includes(status);
    };

    private isDecimal(value: number, divisor: 1 | 100): boolean {
        return value % divisor !== 0;
    }

    public getCreditValue(value: number): string {
        const formatValue = (amount: Amount, divisor: 1 | 100): string =>
            this.isDecimal(value, divisor)
                ? this.basicDataModel.money(amount)
                : this.basicDataModel.money(amount, true);

        switch (this.creditType) {
            case 'bonus-balance': {
                const bonusAmount = this.configComponents.precision.newFromPragmaticNumber(value);
                return this.languagesState.getTranslation(
                    'account.credits.bonusebalance.value',
                    '{value} Bonus Balance',
                    { value: formatValue(bonusAmount, 1) }
                );
            }
            case 'free-bets': {
                const freeBetAmount = this.configComponents.precision.newFromOld(value);
                return this.languagesState.getTranslation('account.credits.freebet.value', '{value} Free Bet', {
                    value: formatValue(freeBetAmount, 100),
                });
            }
            default: {
                // free-spins
                return this.languagesState.getTranslation('account.credits.freespins.value', '{value} Free Spins', {
                    value,
                });
            }
        }
    }

    public checkConditionType(value: unknown): CriteriaReturnType | null {
        const arrayCheck = arrayCriteriaType.safeParse(value);
        if (arrayCheck.success) return { isArray: true, data: arrayCheck.data };

        const primitiveCheck = primitiveCriteriaType.safeParse(value);
        if (primitiveCheck.success) return { isArray: false, data: primitiveCheck.data };

        return null;
    }

    public filterConditions(
        bonus: BonusViewType,
        getGameModelByLaunchGameId: (gameId: string | number) => GameSharedModel | null
    ): FilterConditionsType[] | undefined {
        const isFreebet = bonus.bonusType === 'free-bet';
        const neededConditions = isFreebet
            ? ['bet-min-odds', 'bet-type', 'sport', 'competition', 'event', 'market', 'selection']
            : ['casino-game'];

        const typeMap: Record<string, { type: FilterConditionsType['type']; order: number; isSingle?: boolean }> = {
            'bet-min-odds': { type: 'Min. Odds', order: 3, isSingle: true },
            'bet-type': { type: 'Bet Type', order: 1 },
            sport: { type: 'Eligible Events', order: 2 },
            competition: { type: 'Eligible Events', order: 2 },
            event: { type: 'Eligible Events', order: 2 },
            market: { type: 'Eligible Events', order: 2 },
            selection: { type: 'Eligible Events', order: 2 },
            'casino-game': { type: 'Eligible Games', order: 2 },
        };

        return bonus.usageConditions
            ?.filter((condition) => neededConditions.includes(condition.type))
            .reduce<FilterConditionsType[]>((acc, condition) => {
                const mapped = typeMap[condition.type];
                if (mapped === undefined) return acc;

                const value = this.checkConditionType(condition.value);

                if (value === null) return acc;

                let newValue: string | number | boolean | string[] | (string | boolean | number)[];
                if (condition.type === 'event') {
                    newValue = value.isArray ? value.data.map(this.getEventName) : this.getEventName(value.data);
                } else if (condition.type === 'casino-game') {
                    newValue = value.isArray
                        ? value.data.map((launchGameId: string | number) => {
                              const game = getGameModelByLaunchGameId(launchGameId);
                              return game?.name ?? launchGameId.toString();
                          })
                        : getGameModelByLaunchGameId(value.data.toString())?.name ?? value.data.toString();
                } else {
                    newValue = mapped.isSingle === true ? value.data : value.isArray ? value.data : [value.data];
                }

                const existingItem = acc.find((item) => item.type === mapped.type);

                if (existingItem === undefined) {
                    acc.push({
                        type: mapped.type,
                        value: mapped.isSingle === true ? newValue : [newValue].flat(),
                        order: mapped.order,
                    });
                } else if (mapped.isSingle !== true) {
                    existingItem.value = [existingItem.value, newValue].flat();
                }

                return acc;
            }, [])
            ?.sort((a, b) => a.order - b.order);
    }

    private getEventName = (eventId: string | number | boolean): string => {
        if (typeof eventId === 'boolean') return `Event ID: ${eventId.toString()}`;
        const parsedId = typeof eventId === 'string' ? parseInt(eventId, 10) : eventId;
        return this.common.models.id.getEventId(parsedId).getEventModel()?.name ?? `Event ID: ${eventId}`;
    };

    @computed public get getTotalValue(): string | null {
        if (this.creditType === 'bonus-balance') {
            return this.basicDataModel.money(this.bonusBalanceModel.bonusBalanceSum);
        }

        if (this.bonusBalanceModel.bonusBalances.valueReady?.status === 200) {
            const value =
                this.bonusBalanceModel.bonusBalances.valueReady.body[this.mapCreditTypeToResponse]?.availableAmount ??
                0;

            if (this.mapCreditTypeToResponse === BonusBalanceRewardType.FREESPIN) {
                return value.toString();
            }

            return this.basicDataModel.money(this.configComponents.precision.newFromOld(value));
        }
        return this.basicDataModel.money(new Amount('0'));
    }

    @action public redirectToSportsbook = (): void => {
        this.starRouter.redirectToHomepage();
    };

    @action public redirectToCasino = (): void => {
        this.starRouter.redirectToCasinoPage();
    };

    public mapEligibleGamesToNames(
        eligibleGames: (string | number)[],
        getGameModelByLaunchGameId: (gameId: string | number) => GameSharedModel | null
    ): string[] {
        return eligibleGames.map((launchGameId) => {
            const game = getGameModelByLaunchGameId(launchGameId);
            return game?.name ?? launchGameId.toString();
        });
    }
}
