import styled from "@emotion/styled";
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from "src/withConfig";


export const TotalBalanceValue = withConfig(theme => styled('span', { label: 'TotalBalanceValue' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    text-align: center;
    line-height: 1.4286;
`);

export const RowStyle = withConfig(theme => styled('div', { label: 'RowStyle' })<{type?: 'primary' | 'secondary'}>`
    font-size: ${theme.star.fontSize.small};
    color: ${({ type }): string => type === 'secondary' ? theme.star.accountSummary.txtColorQuaternary : theme.star.accountSummary.txtColorSecondary};
    line-height: 1.3333;
    display: flex;
    align-items: center;
`);

export const ValueStyle = withConfig(theme => styled('div', { label: 'ValueStyle' })<{type?: 'primary' | 'secondary'}>`
    font-size: ${theme.star.fontSize.small};
    color: ${({ type }): string => type === 'secondary' ? theme.star.accountSummary.txtColorQuaternary : theme.star.accountSummary.txtColorSecondary};
    line-height: 1.3333;
`);

export const BalancesCardContainer = styled('div', { label: 'BalancesCardContainer' })`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const PaymentButtonContainer = styled('div', { label: 'PaymentButtonContainer' })`
    display: flex;
    margin-top: 8px;
    gap: 8px;
`;

export const PaymentButton = styled(Button, { label: 'PaymentButton' })`
    width: 100%;
`;
