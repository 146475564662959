import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import {
    PriceIO,
    CountryType,
    CurrencyType,
    PriceType,
    FreeBetCreditsIO,
    FreeBetRemarksIO,
    FreeBetCreditsType,
    SmallLegIO,
    ErrorType,
} from './BetSlipSheredTypes';

export const ErrorsIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, t.unknown]),
    field: t.union([t.null, t.unknown]),
    leg: t.unknown,
    pointer: t.string,
    resource: t.string,
});

export type ErrorsType = t.TypeOf<typeof ErrorsIO>;

export const LegIO = t.interface({
    eachWay: t.union([t.boolean, t.null]),
    errors: t.array(ErrorsIO),
    eventId: t.union([t.number, t.null]), // TODO: check null
    freebetCredits: t.union([t.array(FreeBetCreditsIO), t.null]),
    freebetRemarks: t.union([t.array(FreeBetRemarksIO), t.null]),
    marketId: t.union([t.number, t.null]), // TODO: check null
    maxStake: t.union([t.string, t.undefined, t.null]),
    potentialReturns: t.union([t.string, t.null]),
    potentialReturnsAt: t.union([t.string, t.null]),
    potentialReturnsEw: t.union([t.string, t.null]),
    price: t.union([PriceIO, t.undefined, t.null]),
    priceType: t.string,
    related: t.union([t.boolean, t.null]),
    selectionId: t.union([t.number, t.null]),
    index: t.union([t.number, t.null]),
    stakePerLine: t.union([t.string, t.undefined, t.null]),
    totalStake: t.union([t.string, t.undefined, t.null]),
    timestamp: t.union([t.number, t.null]),
    numLines: t.union([t.number, t.null]),
    uuid: t.union([t.string, t.null]),
    freeBets: t.union([t.undefined, t.null, t.array(t.string)]),
});

export type LegType = t.TypeOf<typeof LegIO>;

export const CombinationsIO = t.interface({
    errors: t.union([t.array(ErrorsIO), t.null]),
    ewOffered: t.boolean,
    eachWay: t.union([t.boolean, t.null]),
    freebetCredits: t.union([t.array(FreeBetCreditsIO), t.null]),
    freebetRemarks: t.union([t.array(FreeBetRemarksIO), t.null]),
    freeBets: t.union([t.undefined, t.null, t.array(t.string)]),
    legs: t.union([t.array(SmallLegIO), t.null]),
    maxStake: t.union([t.string, t.null]),
    name: t.string,
    numLines: t.number,
    potentialReturns: t.union([t.string, t.null]),
    potentialReturnsAt: t.union([t.string, t.null]),
    potentialReturnsEw: t.union([t.string, t.null]),
    price: t.union([PriceIO, t.undefined, t.null]),
    stakePerLine: t.union([t.string, t.null]),
    totalStake: t.union([t.string, t.null]),
    type: t.string,
});

export type CombinationsType = t.TypeOf<typeof CombinationsIO>;

export const ReferralLegTypeIO = t.interface({
    type: t.string,
    sport: t.null,
    eachWayTerms: t.null,
    termsWithBet: t.null,
    eventCountry: t.union([t.string, t.array(t.string), t.array(t.interface({}))]),
    price: t.union([PriceIO, t.undefined, t.null]),
    priceType: t.string,
    eventId: t.number,
    marketId: t.string,
    selectionId: t.string,
    id: t.string,
    index: t.string,
    stakePerLine: t.string,
    eachWay: t.boolean,
    potentialReturns: t.union([t.null, t.undefined, t.string]),
});

export type ReferralLegType = t.TypeOf<typeof ReferralLegTypeIO>;

export const ReferralCombinationTypeIO = t.interface({
    type: t.string,
    correlationID: t.string,
    stakePerLine: t.string,
    eachWay: t.boolean,
    comment: t.null,
    country: t.union([t.undefined, t.null, t.string]),
    channel: t.string,
    potentialReturns: t.union([t.null, t.string]),
    name: t.union([t.undefined, t.string]),
    freebet: t.boolean,
    legs: t.union([t.undefined, t.array(SmallLegIO)]),
});

export type ReferralCombinationType = t.TypeOf<typeof ReferralCombinationTypeIO>;

export const ReferralTypeIO = t.interface({
    legs: t.record(t.string, ReferralLegTypeIO),
    combinations: t.record(t.string, ReferralCombinationTypeIO),
    channel: t.string,
});

export const ReferralTypeModelIO = t.interface({
    combinations: t.record(t.string, ReferralCombinationTypeIO),
    legs: t.record(t.string, ReferralLegTypeIO),
    expiresAt: t.union([t.string, t.null, t.undefined]),
    status: t.string,
    user: t.string,
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export type ReferralTypeModel = t.TypeOf<typeof ReferralTypeModelIO>;

export const decodeReferralType = buildValidator('ReferralModelType', ReferralTypeIO);

interface FreeBetRemarksType {
    resource: string;
    code: string;
    details:
        | {
              minimum: string;
          }
        | { invalid: string }
        | { negative: string };
}

export interface CastBetType {
    channel: ChannelType;
    correlationId: string | null;
    country: string | CountryType | null;
    currency: string | CurrencyType | null;
    eachWay: boolean;
    freebetCredits: Array<FreeBetCreditsType> | null;
    freebetRemarks: Array<FreeBetRemarksType> | null;
    freeBets?: Array<string> | null;
    id: string;
    ip: string;
    legs: Array<unknown>;
    maxStake: string | null;
    potentialReturns: string | null;
    potentialReturnsEw: string | null;
    totalStake: string | null;
    price?: PriceType | null;
    stakePerLine: string;
    type: string;
    numLines: number;
    errors: Array<ErrorType>;
}
