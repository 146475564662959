import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Card } from 'src/domains/players/webview/components/Account/common/card/Card';
import { BadgeGroup } from 'src/domains/players/webview/components/Account/common/badge/BadgeGroup';
import {
    CreditsCardButton,
    CreditsCardConditionsContainer,
    CreditsCardContainer,
    CreditsCardHeader,
    CreditsCardBadgeExpiryAt,
    CreditsCardRow,
    CreditsCardRowLabel,
    CreditsCardRowValue,
    CreditsCardSubHeader,
} from './Credits.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Badge } from 'src/domains/players/webview/components/Account/common/badge/Badge';
import { BonusViewType } from 'src/api_openapi/v2/bonus-engine/schemas';
import { CreditsState } from './Credits.state';
import { GameSharedModel } from 'src/domains/casino/state/AppCasino.state';

interface CreditsBetSpinsCardPropsType {
    bonus: BonusViewType;
    creditsState: CreditsState;
    getGameModelByLaunchGameId: (gameId: string | number) => GameSharedModel | null;
}

export const CreditsBetSpinsCard = observer(
    'CreditsBetSpinsCard',
    ({ bonus, creditsState, getGameModelByLaunchGameId }: CreditsBetSpinsCardPropsType) => {
        return (
            <Card haveBorder={true}>
                <CreditsCardContainer>
                    <div>
                        <CreditsCardHeader>
                            <div>{creditsState.getCreditValue(bonus.currentAmount ?? 0)}</div>
                            <div>
                                {bonus.status === 'active' ? (
                                    <CreditsCardBadgeExpiryAt>
                                        <I18n
                                            langKey='account.credits.badge.expires-at'
                                            defaultText='Expires'
                                        />{' '}
                                        {creditsState.formatExpires(bonus.expiryAt, 'badge')}
                                    </CreditsCardBadgeExpiryAt>
                                ) : (
                                    <Badge type={creditsState.mapBadgeTypeBasedOnStatus(bonus.status)}>
                                        {bonus.status}
                                    </Badge>
                                )}
                            </div>
                        </CreditsCardHeader>
                        {bonus.status === 'active' ? null : (
                            <CreditsCardSubHeader>{creditsState.formatExpires(bonus.expiryAt)}</CreditsCardSubHeader>
                        )}
                    </div>
                    <CreditsCardConditionsContainer>
                        {creditsState.filterConditions(bonus, getGameModelByLaunchGameId)?.map((condition) => {
                            return (
                                <CreditsCardRow key={condition.type}>
                                    <CreditsCardRowLabel>{condition.type}:</CreditsCardRowLabel>

                                    {typeof condition.value === 'string' ||
                                    typeof condition.value === 'number' ||
                                    typeof condition.value === 'boolean' ? (
                                        <CreditsCardRowValue>{condition.value}</CreditsCardRowValue>
                                    ) : (
                                        <BadgeGroup badges={condition.value} />
                                    )}
                                </CreditsCardRow>
                            );
                        })}
                        {/* <CreditsCardRowValue>{bonus.playthrough}x</CreditsCardRowValue> */}
                    </CreditsCardConditionsContainer>
                    {creditsState.creditType === 'free-bets' ? (
                        <CreditsCardButton
                            size='medium'
                            onClick={creditsState.redirectToSportsbook}
                            disabled={creditsState.isButtonDisabled(bonus.status)}
                        >
                            <I18n
                                langKey='account.credits.bet-now'
                                defaultText='Bet Now'
                            />
                        </CreditsCardButton>
                    ) : (
                        <CreditsCardButton
                            size='medium'
                            onClick={creditsState.redirectToCasino}
                            disabled={creditsState.isButtonDisabled(bonus.status)}
                        >
                            <I18n
                                langKey='account.credits.play-now'
                                defaultText='Play Now'
                            />
                        </CreditsCardButton>
                    )}
                </CreditsCardContainer>
            </Card>
        );
    }
);
