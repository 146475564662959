import { observable, computed, action, makeObservable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { EventsCollectionList, EventsCollectionState } from 'src/domains/sportsbook/shared/Types';
import { SliderState } from './racesSlider/Slider.state';

export type RaceSportType = 'horseracing' | 'greyhoundracing';
export type RacesRegionType = 'uk&ireland' | 'all';

const checkIsMarketCreatedWithTrapChallengeTemplate = (templateName: string): boolean => {
    return ['Trap Challenge - Outright', 'Trap Challenge'].includes(templateName);
};

const sortEventsByTime = (outArray: Array<[EventModel, MarketModel]>): Array<[EventModel, MarketModel]> => {
    return outArray.sort((a: [EventModel, MarketModel], b: [EventModel, MarketModel]): number => {
        const time1 = a[0].timeSettingsStartTime;
        const time2 = b[0].timeSettingsStartTime;

        if (time1 < time2) {
            return -1;
        }
        if (time1 > time2) {
            return 1;
        }

        return 0;
    });
};

const getEventsForSportType = (common: Common, sport: RaceSportType): EventsCollectionList => {
    const eventsCollection = EventsCollectionState.get(common);

    if (sport === 'horseracing') {
        const races = eventsCollection.getRaces('horseracing');
        return races.filterBySportAndEvent((_sport, event): boolean => {
            return event.tags['specials'] !== 'yes';
        });
    } else {
        return eventsCollection.getRaces('greyhoundracing');
    }
};

interface EventsForViewType {
    allEvents: Array<[EventModel, MarketModel]>;
    ukIrelandEvents: Array<[EventModel, MarketModel]>;
}

const getFilteredRacingEvents = (allEvents: Array<[EventModel, MarketModel]>): EventsForViewType => {
    const ukIrelandEvents: Array<[EventModel, MarketModel]> = [];

    for (const [event, market] of allEvents) {
        if (event.tagsCountry === 'GB' || event.tagsCountry === 'IE') {
            ukIrelandEvents.push([event, market]);
        }
    }

    return { allEvents, ukIrelandEvents };
};

/* ----------> SLIDER DATA STATE <---------- */
export class SliderDataState {
    public static get = AutoWeakMap.create(
        (common: Common, activeSportTab: RaceSportType) => new SliderDataState(common, activeSportTab)
    );

    private constructor(
        private readonly common: Common,
        private readonly activeSportTab: RaceSportType
    ) {
        makeObservable(this);
    }

    private getEventMarket = (eventId: EventId): 'loading' | null | MarketModel => {
        const eventMarkets = eventId.getEventMarkets();

        if (eventMarkets === null) {
            return 'loading';
        }

        const marketItem = eventMarkets[0];
        if (marketItem === undefined) {
            return null;
        }

        const market = this.common.models.getMarket(marketItem.id);
        if (market === null) {
            return 'loading';
        } else if (checkIsMarketCreatedWithTrapChallengeTemplate(market.template.name)) {
            /* do not include trap challenge into events for view
            no need to appear in nextoff when they have separate section */
            return null;
        } else {
            return market;
        }
    };

    @computed private get racingEventsForViewWithoutFiltering(): Array<[EventModel, MarketModel]> | 'loading' {
        const eventsList: Array<[EventModel, MarketModel]> = [];

        let loadingCounter: number = 0;

        const eventsCollection = getEventsForSportType(this.common, this.activeSportTab).eventItems;
        if (eventsCollection === null) {
            return 'loading';
        }

        for (const event of eventsCollection) {
            const eventModel = event.id2.getEventModel();
            const marketModel = this.getEventMarket(event.id2);

            if (eventModel === null) {
                loadingCounter++;
                continue;
            }

            if (marketModel === 'loading') {
                loadingCounter++;
                continue;
            }

            if (marketModel === null) {
                continue;
            }

            eventsList.push([eventModel, marketModel]);
        }

        if (loadingCounter > 0) {
            return 'loading';
        }

        return sortEventsByTime(eventsList);
    }

    @computed public get racingEventsForView(): EventsForViewType | 'loading' {
        const events = this.racingEventsForViewWithoutFiltering;
        if (events === 'loading') {
            return 'loading';
        }

        const eventsForView: Array<[EventModel, MarketModel]> = [];

        for (const [event, market] of events) {
            if (event.display && !event.timeSettingsStarted) {
                eventsForView.push([event, market]);
            }
        }
        const filteredEvents = getFilteredRacingEvents(eventsForView);
        return filteredEvents;
    }
}

/* ----------> NEXT RACES STATE <---------- */
export class NextRacesState {
    @observable public isNextRacesOpen: boolean = true;
    @observable public isRacesRegionSelected: boolean = true;
    @observable private selectedSport: RaceSportType = 'horseracing';

    public readonly sliderState: SliderState;

    public constructor(
        private readonly common: Common,
        private readonly sportPage?: RaceSportType
    ) {
        makeObservable(this);
        this.sliderState = new SliderState((): number => this.eventsCount);
        console.log('activeSportTab', this.activeSportTab);
    }

    @computed public get activeSportTab(): RaceSportType {
        return this.sportPage === undefined ? this.selectedSport : this.sportPage;
    }

    @computed public get activeRegion(): RacesRegionType {
        if (this.isRacesRegionSelected && this.selectedSport === 'horseracing') {
            return 'uk&ireland';
        } else {
            return 'all';
        }
    }

    @computed public get eventsListToDisplayReady(): Array<[EventModel, MarketModel]> {
        if (this.racesListToDisplay === 'loading') {
            return [];
        }
        if (this.activeRegion === 'uk&ireland') {
            return this.racesListToDisplay.ukIrelandEvents;
        }
        return this.racesListToDisplay.allEvents;
    }

    public get eventsCount(): number {
        return this.eventsListToDisplayReady.length;
    }

    @computed public get racesListToDisplay(): EventsForViewType | 'loading' {
        const racesList = SliderDataState.get(this.common, this.activeSportTab).racingEventsForView;

        if (racesList === 'loading') {
            return 'loading';
        }
        return racesList;
    }

    @computed public get racesTypeFilterButtons(): Array<FiltersType> {
        const languagesState = LanguagesState.get(this.common);
        return [
            {
                key: 'horseracing',
                label: languagesState.getTranslation('next-off.switch-button.horseracing', 'Horse Racing'),
                onClick: (): void => {
                    this.onSportTabChange('horseracing');
                },
            },
            {
                key: 'greyhoundracing',
                label: languagesState.getTranslation('next-off.switch-button.greyhoundracing', 'Greyhound'),
                onClick: (): void => {
                    this.onSportTabChange('greyhoundracing');
                },
            },
        ];
    }

    @action public onSportTabChange = (sport: RaceSportType): void => {
        this.selectedSport = sport;
        this.sliderState.resetSlider();
    };

    @action public toggleNextRacesView = (): void => {
        this.isNextRacesOpen = !this.isNextRacesOpen;
    };

    @action public toggleRacesRegion = (): void => {
        this.isRacesRegionSelected = !this.isRacesRegionSelected;
        this.sliderState.resetSlider();
    };
}
