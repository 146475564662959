import { action, computed, makeObservable } from 'mobx';
import { Session } from 'src_common/sdk/session';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { DataTimeDuration } from 'src_common/utils/time/time';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

class ConnectWrapperTimer {
    public connect(self: MobxValue<number>): NodeJS.Timeout {
        const timer = setInterval((): void => {
            const currentTimeDate = new Date();

            self.setValue(currentTimeDate.getTime());
        }, 1000);
        return timer;
    }

    public dispose(timer: NodeJS.Timeout): void {
        clearInterval(timer);
    }
}

const currentTime: MobxValue<number> = MobxValue.create({
    initValue: 0,
    connect: new ConnectWrapperTimer(),
});

export class AccountSummaryState {
    private readonly session: Session;
    private readonly starRouter: StarRouter;
    private readonly googleTagManager: GoogleTagManagerState;

    public static get = AutoWeakMap.create((common: Common) => new AccountSummaryState(common));

    private constructor(private readonly common: Common) {
        this.session = this.common.session;
        this.starRouter = StarRouter.get(common);
        this.googleTagManager = GoogleTagManagerState.get(common);

        makeObservable(this);
    }

    @action public redirectToTopUp = (): void => {
        this.googleTagManager.routingAccountChange('top-up');
        this.starRouter.redirectToTopUp(null);
    };

    @action public redirectToWithdraw = (): void => {
        this.googleTagManager.routingAccountChange('withdraw');
        this.starRouter.redirectToWithdraw();
    };

    @computed public get loggedCounter(): string {
        if (this.session.loggedTime !== null) {
            const diffInMilliseconds = Math.floor(currentTime.getValue() - this.session.loggedTime);
            const duration = DataTimeDuration.from(diffInMilliseconds);
            const seconds = duration.seconds();
            const minutes = duration.minutes();
            const hours = duration.hours();

            let displayTime = '';

            if (hours > 0) {
                displayTime = `${hours}h `;
            }
            if (minutes > 0) {
                displayTime = `${displayTime}${minutes}m `;
            }
            if (seconds > 0) {
                displayTime = `${displayTime}${seconds}s `;
            }

            return displayTime;
        }
        return '0s';
    }
}
