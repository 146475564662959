import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { GlobalWrapper } from './reset.style';
import { useAppStateContext } from 'src/appState/AppState';
import { RollingDepositLimitPopup } from 'src/domains/players/webview/components/RollingDepositLimitPopup';
import { Overlay } from 'src/domains/layouts/webview/components/overlay/Overlay';
import { QuickBet } from 'src/domains/sportsbook/betting/ui/betSlip/quickBet/QuickBet';
import { HomePageLayout as HomePage } from 'src/domains/layouts/layouts/homepage/Homepage';
import { EventLayout as EventPage } from 'src/domains/sportsbook/webview/layouts/eventPage/EventPage';
import { CompetitionPage } from 'src/domains/sportsbook/webview/layouts/competitionPage/CompetitionPage';
import { InPlayLayout as InPlayPage } from 'src/domains/sportsbook/webview/layouts/inPlayPage/InPlayPage';
import { CasinoPage } from 'src/domains/casino/webview/layouts/casinoPage/CasinoPage';
import { NeBetHomePage } from 'src/domains/layouts/layouts/nebet/homePage/HomePage';
import { SearchPage as Search } from 'src/domains/layouts/layouts/seachPage/SearchPage';
import { NavigationSports } from 'src/domains/layouts/layouts/navigationSportsPage/NavigationSportsPage';
import { Notifications } from 'src/domains/layouts/webview/components/notificationsPage/NotificationsPage';
import { PopupContainer } from 'src/domains/layouts/webview/components/popups/PopupContainer';
import { AppWrapper } from './ui.style';
import { MarketingNotificationsBanner } from 'src/domains/layouts/webview/components/marketingNotificationsBanner/MarketingNotificationsBanner';
import { LiveCasinoPage } from 'src/domains/casino/webview/layouts/liveCasinoPage/LiveCasinoPage';
import { RacingStreamGlobal } from 'src/domains/sportsbook/webview/components/stream/RacingStreamGlobal';
import { AccountSidebar } from 'src/domains/players/webview/components/Account';
import { Banners } from 'src/domains/layouts/webview/components/banners/Banners';
import { DebugPanel } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel';
import { MiniGames, VirtualsPage } from 'src/domains/casino/shared/Components';
import { NoConnection } from 'src/domains/layouts/layouts/utilityPages/errorPage/NoConnection';
import { CookiesPolicyBox } from 'src/domains/layouts/webview/components/cookiesPolicyBox/CookiesPolicyBox';
import { SpecialSportPage } from 'src/domains/layouts/layouts/specialSportPage/SpecialSportPage';
import { SportPage } from 'src/domains/layouts/layouts/sportPage/SportPage';
import { RaceCardPage } from 'src/domains/sportsbook/webview/layouts/raceCardPage/RaceCardPage';
import { SrgQuestionnaire } from 'src/domains/players/state/srgQuestionnaire/srgQuestionnaire/SrgQuestionnaire';
import { LuckyKingPopups } from 'src/domains/players/webview/components/LuckyKingWallet';
import { StarEvents } from 'src/domains/layouts/layouts/starEvents/StarEvents';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { SrgReopenBox } from 'src/domains/layouts/webview/components/srgReopenBox/SrgReopenBox';
import { TraderChatButtonFloating } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/traderChatLinkNav/TraderChatLinkNav';
import { FreshDeskIframe } from 'src/domains/layouts/webview/components/freshDesk/FreshDeskIframe';
import { DepositLimitUpdatePopup } from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopup/DepositLimitUpdatePopup';
import { PofPopups } from 'src/domains/players/webview/components/PofPopups';
import { ssrReactLazy } from 'src_common/utils/Idle';
import { PageNotFoundFallBackLoader } from 'src/domains/layouts/layouts/utilityPages/errorPage/PageNotFound';
import { MainFooter } from 'src/domains/layouts/webview/others/luckyKing/Footer';
import { Footer } from 'src/domains/layouts/webview/modules/layoutSkeleton/Footer';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useCommon } from 'src/domains/common/Common';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { MonthlyActivityPopup } from 'src/domains/layouts/webview/components/popups/monthlyActivityPopup/monthlyActivityPopup';
import { BlockingNotifications } from 'src/domains/players/webview/components/Account/BlockingNotifications/BlockingNotifications';
import { IntercomChat } from 'src/domains/layouts/webview/components/IntercomChat';
import { useEffect } from 'react';
import { SymplifyState } from 'src/domains/layouts/state/symplify/SymplifyState';

//TODO - try to split this file into smaller files

const PageNotFound = ssrReactLazy(
    async () => (await import('src/domains/layouts/layouts/utilityPages/errorPage/PageNotFound')).PageNotFound
);

interface LayoutType {
    content: JSX.Element;
    footer: JSX.Element;
    aside: JSX.Element | null;
}

const AppInner = observer('AppInner', () => {
    const common = useCommon();
    const { appLayoutsState, appPlayersState, appSportsBookState, appCasinoState } = useAppStateContext();

    const featureState = FeatureState.get(common);
    const config = ConfigComponents.get(common).config;
    const router = StarRouter.get(common);
    const env = EnvironmentState.get(common);

    const getMainId = (): string | undefined => {
        return 'app-wrapper';
    };

    const isMarketingNotificationsBannerVisible = (): boolean => {
        const { isBannerAvailable } = appPlayersState.marketingNotificationsState;
        return isBannerAvailable;
    };

    const renderMarketingNotificationsBanner = (): JSX.Element | null => {
        if (isMarketingNotificationsBannerVisible()) {
            return <MarketingNotificationsBanner key='marketing-banner' />;
        }
        return null;
    };

    const renderCookieBox = (): JSX.Element | null => {
        const isMobileApp = env.isMobileApp();

        if (isMobileApp === false && env.isBrowser) {
            return <CookiesPolicyBox key='cookie-banner' />;
        }

        return null;
    };

    const renderSrgBox = (): JSX.Element | null => {
        if (appPlayersState.usersState.isSrgRequested) {
            return <SrgReopenBox key='srg-reopen-banner' />;
        }

        return null;
    };

    const renderBanners = (): JSX.Element => {
        return (
            <Banners>
                {renderMarketingNotificationsBanner()}
                {renderCookieBox()}
                {renderSrgBox()}
            </Banners>
        );
    };

    const renderPopups = (): JSX.Element => {
        return <PopupContainer />;
    };

    const renderCasinoMini = (currentView: RouteViewType | null): JSX.Element | null => {
        const { breakpointsState } = appLayoutsState;

        const desktopVersion = breakpointsState.desktop.isBiggerOrEq;
        const isAllowCasinoInIOSWrapper = featureState.allowCasinoInIOSWrapperNew;
        const isCasino = currentView?.name === 'casino';

        const casinoMini =
            config.casinoMiniGames === true && isAllowCasinoInIOSWrapper ? (
                <MiniGames appCasinoState={appCasinoState} />
            ) : null;

        if (desktopVersion === null || desktopVersion === true || isCasino) {
            return null;
        }
        return casinoMini;
    };

    const { sdkCustomer } = appLayoutsState;
    const { userId } = sdkCustomer.session;

    const renderLayoutWithSidebar = (content: JSX.Element): LayoutType => {
        const accountView = router.accountView;
        const isSignup = accountView?.account === 'signup';

        const isNotificationsList =
            router.currentView?.name === 'notifications-list' || accountView?.account === 'terms-and-conditions-promos';

        const renderFooter = config.cryptoOperatorAccountType ? (
            <MainFooter />
        ) : (
            <Footer currentView={router.currentView} />
        );

        return {
            content,
            footer: renderFooter,
            aside: (
                <AccountSidebar
                    isSignUp={isSignup}
                    currentView={router.currentView}
                    promotionSlug={router.freeParams.promo ?? 'homepage'}
                    isNotificationsList={isNotificationsList}
                />
            ),
        };
    };

    const renderLayout = (): LayoutType | null => {
        const { currentView, accountView } = router;

        const isNotificationsList =
            router.currentView?.name === 'notifications-list' || accountView?.account === 'terms-and-conditions-promos';

        if (currentView !== null) {
            if (currentView.name === 'homepage') {
                if (config.layoutHomepage === 'secondary') {
                    return renderLayoutWithSidebar(
                        <NeBetHomePage
                            accountView={accountView}
                            currentView={currentView}
                            headerStyleLevel='sportLevel'
                            isSearchActive={true}
                        />
                    );
                }
                return renderLayoutWithSidebar(
                    <HomePage
                        accountView={accountView}
                        currentView={currentView}
                        headerStyleLevel='sportLevel'
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'sports') {
                return renderLayoutWithSidebar(
                    <HomePage
                        accountView={accountView}
                        currentView={{ name: 'homepage' }}
                        headerStyleLevel='sportLevel'
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'competition') {
                return renderLayoutWithSidebar(
                    <CompetitionPage
                        currentView={currentView}
                        accountView={accountView}
                        headerStyleLevel='competitionLevel'
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'sport') {
                const { hiddenSportsList } = config;

                if (hiddenSportsList.includes(currentView.id)) {
                    return null;
                }

                return renderLayoutWithSidebar(
                    <SportPage
                        currentView={currentView}
                        accountView={accountView}
                        headerStyleLevel='competitionLevel'
                        isSearchActive={true}
                        isAmericanFootball={currentView.id === 'americanfootball'}
                    />
                );
            }

            if (currentView.name === 'event') {
                return renderLayoutWithSidebar(
                    <EventPage
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                        isNotificationsList={isNotificationsList}
                    />
                );
            }

            if (currentView.name === 'racecard') {
                const sport = currentView.sport;

                if (sport === 'horseracing' || sport === 'greyhoundracing') {
                    const racecardBuildIds =
                        currentView.collection === 'race-meetings' ? currentView.racecardBuildIds : [];

                    return renderLayoutWithSidebar(
                        <RaceCardPage
                            sport={sport}
                            currentView={currentView}
                            accountView={accountView}
                            isSearchActive={true}
                            racecardBuildIds={racecardBuildIds}
                            racecardCollection={currentView.collection}
                            racecardSelected={currentView.selected}
                        />
                    );
                } else {
                    return null;
                }
            }

            if (currentView.name === 'virtuals' && featureState.allowCasinoInIOSWrapperNew === true) {
                return renderLayoutWithSidebar(
                    <VirtualsPage
                        appCasinoState={appCasinoState}
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={false}
                    />
                );
            }

            if (currentView.name === 'sport-special') {
                return renderLayoutWithSidebar(
                    <SpecialSportPage
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'inplay') {
                return renderLayoutWithSidebar(
                    <InPlayPage
                        accountView={accountView}
                        currentView={currentView}
                        headerStyleLevel='sportLevel'
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'casino') {
                if (featureState.allowCasinoInIOSWrapperNew === false) {
                    return null;
                }

                return renderLayoutWithSidebar(
                    <CasinoPage
                        appCasinoState={appCasinoState}
                        currentView={currentView}
                        accountView={accountView}
                        isSearchActive={false}
                    />
                );
            }

            if (currentView.name === 'live-casino') {
                if (featureState.allowCasinoInIOSWrapperNew === false) {
                    return null;
                }

                return renderLayoutWithSidebar(
                    <LiveCasinoPage
                        appCasinoState={appCasinoState}
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={false}
                    />
                );
            }

            if (currentView.name === 'search') {
                return renderLayoutWithSidebar(
                    <Search
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'navigation-sports') {
                return renderLayoutWithSidebar(
                    <NavigationSports
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                    />
                );
            }

            if (currentView.name === 'notifications-list') {
                return renderLayoutWithSidebar(
                    <Notifications
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                        isNotificationsList={isNotificationsList}
                    />
                );
            }

            if (currentView.name === 'starevents' && config.eventsOnLaterHomePage) {
                return renderLayoutWithSidebar(
                    <StarEvents
                        accountView={accountView}
                        currentView={currentView}
                        isSearchActive={true}
                    />
                );
            }
        }

        return null;
    };

    const layout = renderLayout();
    const layoutWithAside = layout?.aside ?? null;

    const overlayOnClick = (): void => {
        //Handler for overlay - to improve in the future
        // console.info('overlay on click');
        appLayoutsState.overlay.toggleMenu();
    };

    const name = router.currentView?.name ?? null;
    const isAnyCasinoPage = name === 'casino' || name === 'virtuals' || name === 'live-casino';
    const isRaceCard = name === 'racecard';
    const currentView = router.currentView;
    const accountView = router.accountView;

    const isNotificationsList =
        router.currentView?.name === 'notifications-list' || accountView?.account === 'terms-and-conditions-promos';

    const { streamBoxHeight, isStreamFloating, isStreamOpen } = appSportsBookState.streamingState;
    const { boxHeight: bannersHeight } = appLayoutsState.bannersBoxState;
    const { shouldOverlayOpen, overlayLevel: overlayIndex } = appLayoutsState.overlay;

    const {
        srgQuestionnaire: isSrgQuestionnaire,
        traderChat,
        freshDeskChat,
        rollingNetDepositLimit,
        intercomChat,
    } = config;

    const { usersState, blockingNotificationsState } = appPlayersState;

    const traderChatState = TraderChatState.get(common);
    const isAvailableBubbleChatButton = traderChat.isBubbleChatButton && traderChatState.isTraderChatForView;

    const { networkConnectionState } = appLayoutsState;
    const { showOfflineMessage } = networkConnectionState;
    const isMobileApp = env.isMobileApp();

    if (blockingNotificationsState.getBlockingNotificationType !== null) {
        return (
            <BlockingNotifications blockingNotificationType={blockingNotificationsState.getBlockingNotificationType} />
        );
    }

    return (
        <AppWrapper
            data-track={userId}
            id={getMainId()}
            bannersHeight={bannersHeight}
            streamHeight={streamBoxHeight}
            isStreamFloating={isStreamFloating}
            isLayoutHasAside={layoutWithAside !== null}
        >
            {showOfflineMessage && isMobileApp === false ? <NoConnection /> : null}
            {isStreamOpen ? (
                <RacingStreamGlobal
                    isRaceCard={isRaceCard}
                    isAnyCasinoPage={isAnyCasinoPage}
                    isNotificationsList={isNotificationsList}
                />
            ) : null}
            {renderBanners()}
            {layout === null ? (
                <React.Suspense fallback={<PageNotFoundFallBackLoader />}>
                    <PageNotFound
                        accountView={accountView}
                        currentView={currentView}
                    />
                </React.Suspense>
            ) : (
                layout.content
            )}

            {layout === null ? null : layout.footer}
            {layout === null ? null : layout.aside}
            {rollingNetDepositLimit === true ? <RollingDepositLimitPopup /> : null}
            {rollingNetDepositLimit === true ? <DepositLimitUpdatePopup /> : null}
            {usersState.isMonthlyActivityPopupOpen === false ? null : <MonthlyActivityPopup />}
            <QuickBet />
            {renderPopups()}
            {usersState.isSrgModalOpen && isSrgQuestionnaire ? <SrgQuestionnaire /> : null}
            {isAvailableBubbleChatButton ? <TraderChatButtonFloating /> : null}
            {freshDeskChat === null ? null : <FreshDeskIframe />}
            {layout === null ? null : renderCasinoMini(currentView)}
            <Overlay
                isVisible={shouldOverlayOpen}
                onClick={overlayOnClick}
                zIndex={overlayIndex}
            />
            <PofPopups />
            <LuckyKingPopups />
            {intercomChat === true ? <IntercomChat /> : null}
        </AppWrapper>
    );
});

export const App = observer('App', () => {
    const common = useCommon();

    const starRouter = StarRouter.get(common);
    const { currentView } = starRouter;

    const env = EnvironmentState.get(common);

    useEffect(() => {
        SymplifyState.get(common).initCarmaRoi();
    }, []);

    return (
        <>
            <GlobalWrapper
                theme={env.emotionTheme}
                currentView={currentView}
            />
            <AppInner />
            <DebugPanel currentView={currentView} />
        </>
    );
});
