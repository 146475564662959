import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';

export const RowsContainerBonuses = styled('div', { label: 'RowsContainerBonuses' })`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const RowRecordStyleBonuses = styled('div', { label: 'RowRecordStyleBonuses' })`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
`;

export const UserRecordStyleBonuses = styled(RowRecordStyleBonuses, { label: 'UserRecordStyleBonuses' })`
    margin: 0;
`;

export const UserNameStyleBonuse = withConfig(theme => styled('div', { label: 'UserNameStyleBonuse' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    max-width: 70%;
    line-height: 1.4285;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

export const AccountSummaryRowText = withConfig(theme => styled('div', { label: 'AccountSummaryRowText' })`
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.accountSummary.txtColor};
    line-height: 1.3333;
    display: flex;
    align-items: center;
`);

export const AccountSummaryValue = withConfig(theme => styled('div', { label: 'AccountSummaryValue' })`
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.accountSummary.txtColor};
    line-height: 1.3333;
`);

export const AccountSummaryWrapperBonuse = withConfig(theme => styled('div', { label: 'AccountSummaryWrapperBonuse' })`
    color: ${theme.star.accountSummary.txtColor};
    background-color: ${theme.star.accountSummary.bgColorSecondary};
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
`);

export const AccountSummaryStatusWrapper = withConfig(theme => styled('div', { label: 'AccountSummaryStatusWrapper' })`
    color: ${theme.star.accountDrawer.txtColor};
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`);

export const HelpIconWrapper = styled(HelpIcon, { label: 'HelpIconWrapper' })`
    cursor: pointer;
    fill: currentcolor;
    width: 16px;
    margin: 0 5px;
`;

export const InfoIconStyled = styled(InfoIcon, { label: 'InfoIconStyled' })`
    width: 16px;
    height: 16px;
    margin-left: 4px;
`;

