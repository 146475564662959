import { CasinoCollectionModelExt } from './CasinoCollectionModelExt';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { CasinoGameModelExt } from './CasinoGameModelExt';
import { CasinoContextModelExt } from 'src/domains/casino/state/AppCasino/store/game-store/CasinoContextModelExt';
import {
    CategoriesAndGamesResponseType,
    CollectionType,
} from 'src/domains/casino/state/AppCasino/store/game-store/typesExt';
import { openapiCmsAnonymousGetCategoriesAndGamesRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_anonymous_get_categories_and_games';
import { CasinoSearchExt } from './CasinoSearchExt';

const newCollectionCasinoExt = (
    search: CasinoSearchExt,
    contextModel: CasinoContextModelExt,
    collectionId: CollectionType
): CasinoCollectionModelExt => {
    const promise = contextModel.session.call(openapiCmsAnonymousGetCategoriesAndGamesRequest, {
        collection_slug: collectionId,
    });
    const resource = new Resource(async (): Promise<CategoriesAndGamesResponseType> => promise);

    return CasinoCollectionModelExt.newFromParams(search, collectionId, resource, contextModel);
};

export class GameStoreExt {
    private data: MobxMapAutoNew<CollectionType, CasinoCollectionModelExt>;

    public constructor(
        search: CasinoSearchExt,
        public readonly contextModel: CasinoContextModelExt
    ) {
        this.data = new MobxMapAutoNew((collectionId: CollectionType) => {
            return newCollectionCasinoExt(search, contextModel, collectionId);
        });
    }

    public getCollection(id: CollectionType): CasinoCollectionModelExt {
        return this.data.get(id);
    }

    private get allCollections(): Array<CasinoCollectionModelExt> {
        const allCollections: Array<CasinoCollectionModelExt> = [];
        for (const collection of this.contextModel.config.commonConfig.casinoPublishedCollections) {
            if (collection !== 'bingo') {
                const data = this.getCollection(collection);
                allCollections.push(data);
            }
        }
        return allCollections;
    }

    public getGameModel(id: number): CasinoGameModelExt | null {
        for (const collection of this.allCollections) {
            const model = collection.getGameModel(id);

            if (model.data !== null) {
                return model;
            }
        }

        return null;
    }

    public getGameModelByLaunchGameId(id: string | number): CasinoGameModelExt | null {
        for (const collection of this.allCollections) {
            for (const game of collection.games) {
                if (game.launchGameId.toString() === id.toString()) {
                    return game;
                }
            }
        }

        return null;
    }

    public isGameExists(gameId: number): boolean {
        const game = this.getGameModel(gameId);

        if (game === null) {
            return false;
        }

        return game.data === null ? false : true;
    }
}
