import React, { useEffect } from 'react';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    AlignBottomWrapper,
    ForgotPasswordLink,
    LoginBtnWrapper,
    LoginForm,
    LoginInput,
    LoginMessage,
    LoginTabWrapper,
    SignUpHelper,
    SignUpLink,
    RememberMeWrapper,
} from './LoginTab.style';
import { LoginTabUtil } from 'src/domains/players/webview/components/Account/loginTab/LoginTabUtil';
import { observer } from 'src/utils/mobx-react';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const onSubmitDisable = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

interface LoginTabPropsType {
    isLoginRoute?: boolean;
}

export const LoginTab = observer('LoginTab', ({ isLoginRoute }: LoginTabPropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState, starRouter } = appLayoutsState;
    const { loginState } = appPlayersState;

    const emailLabel = LoginTabUtil.getLoginInputLabel();
    const passwordLabel = LoginTabUtil.getPasswordInputLabel();

    useEffect(() => {
        return loginState.resetState;
    }, []);

    return (
        <LoginTabWrapper
            key='login-tab'
            data-test='LoginTab'
        >
            <LoginForm
                key='login-form'
                onSubmit={onSubmitDisable}
                id='login'
            >
                {isLoginRoute === true ? null : starRouter.isAccountParam === false ? null : (
                    <LoginMessage>
                        {languagesState.getTranslation('account.tabs.login.message', 'Login to access page')}
                    </LoginMessage>
                )}
                <LoginInput
                    name='email'
                    type='email'
                    label={emailLabel}
                    state={loginState.emailState}
                    colorTheme='light'
                    dataTest='email-input'
                />
                <LoginInput
                    name='password'
                    type={loginState.isShowPassword ? 'text' : 'password'}
                    label={passwordLabel}
                    state={loginState.passwordState}
                    showPassword={loginState.setShowPassword}
                    showPasswordFlag={true}
                    isShowPassword={loginState.isShowPassword}
                    colorTheme='light'
                    dataTest='password-input'
                />

                {loginState.loginErrorMsg === null ? null : (
                    <Messages
                        type='error'
                        hasIcon={true}
                        marginBottom='16px'
                        message={loginState.loginErrorMsg}
                    />
                )}

                <RememberMeWrapper key='form-buttons'>
                    <ForgotPasswordLink
                        key='forgot-password'
                        to={{ account: 'forgot-password' }}
                        routeNameForGtm='forgot-password'
                        dataTest='forgot-password'
                    >
                        <I18n
                            langKey='account.forgot-password'
                            defaultText='Forgot password?'
                        />
                    </ForgotPasswordLink>
                </RememberMeWrapper>

                <LoginBtnWrapper
                    type='submit'
                    size='large'
                    onClick={loginState.logIn}
                    disabled={loginState.inProgress || !loginState.isFormValid}
                    dataTest='login-button'
                >
                    <I18n
                        defaultText='Login'
                        langKey='account.login'
                    />
                </LoginBtnWrapper>

                <SignUpHelper>
                    <I18n
                        langKey='account.forgot-password.no-account'
                        defaultText='Don’t have an account? '
                    />
                    <SignUpLink
                        to={{ account: 'signup', promo: null }}
                        routeNameForGtm='signup'
                        dataTest='login-tab-sign-up'
                    >
                        <I18n
                            langKey='account.forgot-password.sign-up.link'
                            defaultText='Sign Up'
                        />
                    </SignUpLink>
                </SignUpHelper>
            </LoginForm>

            <AlignBottomWrapper>
                <AccountHelperInfo key='login-tab-need-help' />
            </AlignBottomWrapper>
        </LoginTabWrapper>
    );
});
