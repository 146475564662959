import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { CardStyled } from './Card.style';

interface CardPropsType {
    children: React.ReactNode;
    haveBorder?: boolean;
}

export const Card = observer('Card', ({ children, haveBorder = false }: CardPropsType) => (
    <CardStyled haveBorder={haveBorder}>{children}</CardStyled>
));
