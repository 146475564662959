import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { RaceSummaryTwoColumnsList } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { RaceSummaryRowExtraPlacesNew } from './extraPlacesSummary/RaceSummaryRowExtraPlacesNew';
import { ExtraPlacesSummaryPropsType, ExtraPlacesSummaryState, getSelectionByName } from './ExtraPlacesSummary.state';
import {
    getPlace,
    NotRunningPriceView,
    getHorseRacingRunner,
} from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { ScrollRowWrapper } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScrollNew.style';
import {
    ExtraPlacesSelectionCast,
    ExtraPlacesRaceSummaryCaption,
    ExtraPlacesRunnerCaption,
    ExtraPlacesSelectionCaption,
} from './extraPlacesSummary/ExtraPlacesSummaryNew.style';

function selectionsBuilder(
    selectionName: string,
    eachWayMarkets: MarketModel[],
    isNonRunnerRow: boolean,
    oddsType: 'f' | 'd',
    raceSummaryRowHorseRacingNew: boolean
): Array<JSX.Element | null> | null {
    return eachWayMarkets.map((market) => {
        const selectionInCurrentRow = getSelectionByName(selectionName, market);
        if (isNonRunnerRow === true) {
            return (
                <NotRunningPriceView
                    key={selectionInCurrentRow?.id2.toOldId()}
                    extraPlaces={true}
                />
            );
        }

        if (selectionInCurrentRow === null) {
            return null;
        }
        if (raceSummaryRowHorseRacingNew) {
            return (
                <ExtraPlacesSelectionCast
                    key={selectionInCurrentRow.id2.key}
                    selectionId={selectionInCurrentRow.id2}
                    title={oddsType === 'd' ? selectionInCurrentRow.price?.d : selectionInCurrentRow.price?.f}
                    borderLeft={true}
                    index={selectionInCurrentRow.id2.toOldId()}
                />
            );
        }
        return (
            <ExtraPlacesSelectionCast
                key={selectionInCurrentRow.id2.key}
                selectionId={selectionInCurrentRow.id2}
                title={oddsType === 'd' ? selectionInCurrentRow.price?.d : selectionInCurrentRow.price?.f}
                borderLeft={true}
                index={selectionInCurrentRow.id}
            />
        );
    });
}

export const ExtraPlacesSummaryNew = observer(
    'ExtraPlacesSummaryNew',
    (propsIn: ExtraPlacesSummaryPropsType): JSX.Element | null => {
        const props = useAsObservableSource(propsIn);
        const { appSportsBookState } = useAppStateContext();
        const common = useCommon();
        const config = ConfigComponents.get(common).config;

        const [state] = useState(() => new ExtraPlacesSummaryState(props));

        const selectionsByPrice = state.selectionsByPrice;
        if (selectionsByPrice === undefined) {
            return null;
        }

        const firstSelection = selectionsByPrice[0];
        if (firstSelection === undefined) {
            return null;
        }

        const marketModel = firstSelection.getMarket();
        if (marketModel === null) {
            return null;
        }
        const oddsTypeShort = appSportsBookState.getOddsFormat();
        // const oddsType = appState.accountState.account?.basicDataReady?.oddsFormat !== undefined ? appState.accountState.account?.basicDataReady?.oddsFormat : 'fractal';

        const StaticList = (): JSX.Element => {
            return (
                <>
                    <ExtraPlacesRaceSummaryCaption>
                        <ExtraPlacesRunnerCaption>
                            <I18n
                                langKey='events.race-summary.runner.label'
                                defaultText='Runner'
                            />
                        </ExtraPlacesRunnerCaption>
                    </ExtraPlacesRaceSummaryCaption>

                    {selectionsByPrice.map((selection) => {
                        const horseRacingRunner = getHorseRacingRunner(selection, false, false);
                        return (
                            <RaceSummaryRowExtraPlacesNew
                                key={selection.id2.key}
                                isNonRunner={state.isNonRunnerInRow(selection.name)}
                                selectionId={selection.id2}
                                runner={horseRacingRunner}
                                place={getPlace(selection)}
                            />
                        );
                    })}
                </>
            );
        };

        const ScrollList = (): JSX.Element => {
            return (
                <>
                    <ExtraPlacesRaceSummaryCaption>
                        {state.marketsPlaceAndReduction.map((market) => (
                            <ExtraPlacesSelectionCaption key={market.marketId.key}>
                                {market.places}
                                <br />
                                {market.reduction}
                            </ExtraPlacesSelectionCaption>
                        ))}
                    </ExtraPlacesRaceSummaryCaption>
                    {selectionsByPrice.map((selection) => (
                        <ScrollRowWrapper
                            key={`price-row-${selection.id2.key}`}
                            isNonRunner={state.isNonRunnerInRow(selection.name)}
                        >
                            {selectionsBuilder(
                                selection.name,
                                state.sortedMarketsByTermPlaces,
                                state.isNonRunnerInRow(selection.name),
                                oddsTypeShort,
                                config.raceSummaryRowHorseRacingNew
                            )}
                        </ScrollRowWrapper>
                    ))}
                </>
            );
        };

        return (
            <RaceSummaryTwoColumnsList
                staticListElements={StaticList()}
                scrollListElements={ScrollList()}
                columnCount={state.marketsPlaceAndReduction.length}
            />
        );
    }
);
