import styled from "@emotion/styled";
import { withConfig } from "src/withConfig";

export const BadgeGroupStyled = styled('div', { label: 'BadgeGroupStyled' })`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`;

export const BadgeDropdownContainer = styled('div', { label: 'BadgeDropdownContainer' })`
    position: relative;

    &:hover .dropdown-content {
        visibility: visible;
    }
`;

export const BadgeDropdownContent = withConfig(theme => styled('div', { label: 'BadgeDropdownContent' })`
    position: absolute;
    padding-top: 4px;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    visibility: hidden;
    right: 0;
`);

export const BadgeDropdownInner = withConfig(theme => styled('div', { label: 'BadgeDropdownInner' })`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 128px;
    max-height: 168px;
    background: ${theme.star.popup.bgColor};
    color: ${theme.star.popup.txtColor};
    padding: 8px 16px 8px 8px;
    overflow-y: scroll;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.32) ${theme.star.popup.bgColor};
`);
