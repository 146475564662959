import * as t from 'io-ts';
import { ChannelIO } from 'src/domains/sportsbook/betting/betting/types';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const PriceIO = t.union([
    t.interface({
        d: t.number,
        f: t.string,
    }),
    t.null,
]);

export const FreeBetRemarksIO = t.interface({
    resource: t.string,
    code: t.string,
    details: t.union([
        t.interface({ minimum: t.string }),
        t.interface({ invalid: t.string }),
        t.interface({ negative: t.string }),
    ]),
});

export const FreeBetCreditsIO = t.interface({
    id: t.number,
    amount: t.string,
});

export const ResponsePostBetsWithCreditsIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: t.interface({
            bets: t.array(
                t.interface({
                    ip: t.union([t.string, t.null, t.undefined]),
                    channel: ChannelIO,
                    eachWay: t.boolean,
                    stakePerLine: t.string,
                    freebetCredits: t.union([t.array(FreeBetCreditsIO), t.undefined]),
                    remarks: t.union([t.array(FreeBetRemarksIO), t.undefined]),
                    correlationId: t.union([t.string, t.null, t.undefined]),
                    legs: t.array(t.unknown),
                    type: t.string,
                })
            ),
        }),
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: t.unknown,
    }),
]);

export const SmallLegIO = t.interface({
    type: t.union([t.undefined, t.string]),
    selection: t.union([t.undefined, t.interface({ id: t.number })]),
    market: t.union([t.undefined, t.interface({ id: t.number })]),
    event: t.union([t.undefined, t.interface({ id: t.number })]),
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.union([t.undefined, t.string]),
});

export type ResponsePostBetsWithCreditsType = t.TypeOf<typeof ResponsePostBetsWithCreditsIO>;
export const decodePostBetsWithCredits: (data: unknown) => ResponsePostBetsWithCreditsType | Error =
    buildValidator<ResponsePostBetsWithCreditsType>('initApiPostBetsWithCredits', ResponsePostBetsWithCreditsIO);
export const ResponsePostBaseResponseIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: t.interface({
            legCombinations: t.array(
                t.interface({
                    id: t.string,
                    bets: t.array(
                        t.interface({
                            type: t.string,
                            name: t.string,
                            ewOffered: t.boolean,
                            legs: t.union([t.undefined, t.array(SmallLegIO)]),
                            numLines: t.number,
                            delay: t.number,
                            visibleForCustomer: t.union([t.boolean, t.null, t.undefined]),
                            visibleForBackend: t.union([t.boolean, t.null, t.undefined]),
                            potentialReturns: t.union([t.string, t.null, t.undefined]),
                            potentialReturnsEw: t.union([t.string, t.null, t.undefined]),
                            freeBets: t.union([t.undefined, t.array(t.string)]),
                        })
                    ),
                    problems: t.union([
                        t.undefined,
                        t.array(
                            t.interface({
                                pointer: t.string,
                                resource: t.string,
                                field: t.union([t.string, t.null, t.undefined]),
                                debugDetails: t.union([t.string, t.null, t.undefined]),
                                details: t.union([t.string, t.null, t.undefined]),
                                code: t.string,
                                leg: t.union([t.undefined, SmallLegIO]),
                            })
                        ),
                    ]),
                })
            ),
            selectedBets: t.array(t.interface({})),
            problems: t.array(t.interface({})),
        }),
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: t.unknown,
    }),
]);

type ResponsePostBaseResponseType = t.TypeOf<typeof ResponsePostBaseResponseIO>;
export const decodePostBaseResponse: (data: unknown) => ResponsePostBaseResponseType | Error =
    buildValidator<ResponsePostBaseResponseType>('initApiPostBaseResponse', ResponsePostBaseResponseIO);
export const ResponsePreviousPricesResponseIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: t.interface({
            legCombinations: t.array(
                t.interface({
                    id: t.string,
                    bets: t.array(
                        t.interface({
                            type: t.string,
                            name: t.string,
                            ewOffered: t.boolean,
                            legs: t.array(t.interface({})),
                            numLines: t.number,
                            delay: t.number,
                            visibleForCustomer: t.union([t.boolean, t.null, t.undefined]),
                            visibleForBackend: t.union([t.boolean, t.null, t.undefined]),
                            potentialReturns: t.union([t.string, t.null, t.undefined]),
                            potentialReturnsEw: t.union([t.null, t.undefined]),
                            freeBets: t.union([t.undefined, t.array(t.string)]),
                        })
                    ),
                    problems: t.array(t.interface({})),
                })
            ),
            selectedBets: t.array(
                t.interface({
                    type: t.string,
                    stakePerLine: t.string,
                    currency: t.string,
                    legs: t.array(t.interface({})),
                    eachWay: t.boolean,
                    totalStake: t.union([t.string, t.null, t.undefined]),
                    potentialReturns: t.union([t.string, t.undefined, t.null]),
                    price: t.union([PriceIO, t.null, t.undefined]),
                    maxStake: t.union([t.string, t.null, t.undefined]),
                })
            ),
            problems: t.array(t.interface({})),
            selectedBetsInfo: t.union([
                t.undefined,
                t.null,
                t.interface({
                    numLinesSum: t.union([t.number, t.null, t.undefined]),
                    totalPotentialReturn: t.union([t.string, t.null, t.undefined]),
                    totalMinReturn: t.union([t.string, t.null, t.undefined]),
                    calculatedStackPerLine: t.union([t.string, t.null, t.undefined]),
                }),
            ]),
        }),
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: t.unknown,
    }),
]);
type ResponsePreviousPricesResponseType = t.TypeOf<typeof ResponsePreviousPricesResponseIO>;
export const decodePreviousPricesResponse: (data: unknown) => ResponsePreviousPricesResponseType | Error =
    buildValidator<ResponsePreviousPricesResponseType>(
        'initApiPreviousPricesResponse',
        ResponsePreviousPricesResponseIO
    );

export const ErrorIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, t.unknown]),
    field: t.union([t.null, t.unknown]),
    leg: t.union([t.undefined, SmallLegIO]),
    pointer: t.string,
    resource: t.string,
});

export const ResponsePostBetsResponseIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: t.interface({
            legCombinations: t.array(
                t.interface({
                    id: t.string,
                    bets: t.array(
                        t.interface({
                            type: t.string,
                            name: t.string,
                            ewOffered: t.boolean,
                            legs: t.array(t.interface({})),
                            numLines: t.number,
                            delay: t.number,
                            visibleForCustomer: t.union([t.boolean, t.null, t.undefined]),
                            visibleForBackend: t.union([t.boolean, t.null, t.undefined]),
                            potentialReturns: t.union([t.string, t.null, t.undefined]),
                            potentialReturnsEw: t.union([t.null, t.undefined]),
                            freeBets: t.union([t.undefined, t.array(t.string)]),
                        })
                    ),
                    problems: t.array(t.interface({})),
                })
            ),
            selectedBets: t.array(
                t.interface({
                    type: t.string,
                    stakePerLine: t.string,
                    currency: t.string,
                    legs: t.array(SmallLegIO),
                    eachWay: t.boolean,
                    totalStake: t.union([t.string, t.undefined, t.null]),
                    potentialReturns: t.union([t.string, t.undefined, t.null]),
                    potentialReturnsEw: t.union([t.string, t.undefined, t.null]),
                    price: t.union([PriceIO, t.null, t.undefined]),
                    maxStake: t.union([t.string, t.null, t.undefined]),
                    freebetCredits: t.union([t.undefined, t.array(FreeBetCreditsIO)]),
                    problems: t.union([t.undefined, t.array(ErrorIO)]),
                })
            ),
            problems: t.union([
                t.undefined,
                t.array(
                    t.interface({
                        code: t.string,
                        debugDetails: t.union([t.null, t.unknown]),
                        details: t.union([
                            t.null,
                            t.undefined,
                            t.interface({
                                requiredAmount: t.union([t.number, t.undefined, t.null]),
                                currentAmount: t.union([t.number, t.undefined, t.null]),
                            }),
                        ]),
                        field: t.union([t.null, t.unknown]),
                        leg: t.unknown,
                        pointer: t.union([t.string, t.null, t.undefined]),
                        resource: t.string,
                    })
                ),
            ]),
        }),
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: t.unknown,
    }),
]);
type ResponsePostBetsResponseType = t.TypeOf<typeof ResponsePostBetsResponseIO>;
export const decodePostBetsResponse: (data: unknown) => ResponsePostBetsResponseType | Error =
    buildValidator<ResponsePostBetsResponseType>('initApiPostBetsResponse', ResponsePostBetsResponseIO);

const RabBodyJson = t.interface({
    stake: t.number,
    price: t.number,
    payout: t.number,
    priceFractional: t.string,
});

export const ResponsePostRabBetsResponseIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: RabBodyJson,
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: RabBodyJson,
    }),
]);

type ResponsePostRabBetsResponseType = t.TypeOf<typeof ResponsePostRabBetsResponseIO>;
export const decodePostRabBetsResponse: (data: unknown) => ResponsePostRabBetsResponseType | Error =
    buildValidator<ResponsePostRabBetsResponseType>('initApiPostRabBetsResponse', ResponsePostRabBetsResponseIO);
