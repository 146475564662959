import { RaceSummaryCaption, RunnerCaption, RunnerCell, SelectionCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const ExtraPlacesRaceSummaryCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'ExtraPlacesRaceSummaryCaption' })`
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: ${theme.star.selection.borderColor};
    height: 40px;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        border-width: 1px 0 1px 1px;
    }
`);

export const ExtraPlacesRunnerCaption = withConfig(theme => styled(RunnerCaption, { label: 'ExtraPlacesRunnerCaption' })`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    margin-right: -1px;
`);

export const ExtraPlacesSelectionCaption = styled(SelectionCaption, { label: 'ExtraPlacesSelectionCaption' })`
    flex: 0 0 64px;
`;

export const ExtraPlacesSelectionCast = withConfig(theme => styled(SelectionCast, { label: 'ExtraPlacesSelectionCast' })`
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    flex: 0 0 64px;
    margin-left: 0;
    @media ${theme.star.mediaQuery.tablet} {
        margin-left: -1px;
    }
`);

export const ExtraPlacesRunnerCell = styled(RunnerCell, { label: 'ExtraPlacesRunnerCell' })`
    border-right: 0;
    flex: 1 1 0%;
`;
