import { computed, makeObservable } from 'mobx';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';

export function getSelectionByName(selectionName: string, marketModel: MarketModel): SelectionModel | null {
    for (const selection of marketModel.selections) {
        if (selection.name === selectionName) {
            return selection;
        }
    }

    return null;
}

export type ExtraPlacesSummaryPropsType = {
    race: EventModel;
};

type MarketsPlaceAndReductionType = {
    places: string;
    reduction: string;
    marketId: MarketId;
};
export class ExtraPlacesSummaryState {
    public constructor(private readonly extraPlacesProps: ExtraPlacesSummaryPropsType) {
        makeObservable(this);
    }

    @computed public get sortedMarketsByTermPlaces(): Array<MarketModel> {
        const sortedMarkets: Array<MarketModel> = this.extraPlacesProps.race.marketExtraPlaces.sort(
            (marketA: MarketModel, marketB: MarketModel) => {
                const marketAPlaces = marketA.eachWayTermsPlaces;
                const marketBPlaces = marketB.eachWayTermsPlaces;

                if (marketAPlaces === undefined || marketBPlaces === undefined) {
                    return 0;
                }

                return marketAPlaces - marketBPlaces;
            }
        );

        return sortedMarkets;
    }

    @computed public get marketsPlaceAndReduction(): Array<MarketsPlaceAndReductionType> {
        const marketsSimpleData: Array<MarketsPlaceAndReductionType> = [];

        for (const market of this.sortedMarketsByTermPlaces) {
            const eachWay = market.eachWay;
            if (eachWay !== undefined) {
                const terms = eachWay.terms[0];

                if (terms !== undefined) {
                    const places = `${terms.places} Places`;
                    const reduction = `EW${terms.reduction}`;

                    const data = {
                        places,
                        reduction,
                        marketId: market.id2,
                    };

                    marketsSimpleData.push(data);
                }
            }
        }

        return marketsSimpleData;
    }

    @computed public get eachWaySelections(): Array<SelectionModel> | null {
        const eachWayMarket = this.sortedMarketsByTermPlaces[0];

        if (eachWayMarket !== undefined) {
            return eachWayMarket.selections;
        }

        return null;
    }

    //HardCoded sorted by price - not support for OrderBy from market template.
    @computed public get selectionsByPrice(): Array<SelectionModel> | undefined {
        const selections = this.eachWaySelections?.sort((selectionA, selectionB) => {
            const priceA = selectionA.price?.d;
            const priceB = selectionB.price?.d;
            if (priceA === undefined || priceB === undefined) {
                return 0;
            }
            return priceA - priceB;
        });

        if (selections === undefined) {
            return selections;
        }
        const sortedViaNonRunner = [];
        const sortedWithRunner = [];
        for (const selection of selections) {
            const nonRunner = this.isNonRunnerInRow(selection.name);
            if (nonRunner === true) {
                sortedViaNonRunner.push(selection);
            } else {
                sortedWithRunner.push(selection);
            }
        }

        return [...sortedWithRunner, ...sortedViaNonRunner];
    }

    public isNonRunnerInRow = (selectionName: string): boolean => {
        const markets = this.sortedMarketsByTermPlaces;
        let isNonRunnerInRow = false;
        for (const market of markets) {
            const selectionInRow = getSelectionByName(selectionName, market);

            if (selectionInRow?.resultType === 'void') {
                isNonRunnerInRow = true;
            }
        }

        return isNonRunnerInRow;
    };
}
