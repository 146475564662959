import styled from "@emotion/styled";
import { withConfig } from "src/withConfig";
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { Link } from "src/domains/layouts/webview/components/router/NewLink";
import { GiftIcon } from 'src/domains/layouts/shared/assets/icons/GiftIcon';
import { RewardIcon } from 'src/domains/layouts/shared/assets/icons/RewardIcon';

export const RowsContainerBonuse = styled('div', { label: 'RowsContainerBonuse' })`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const CardsBalancesRowsContainer = styled('div', { label: 'CardsBalancesRowsContainer' })`
    display: flex;
    gap: 8px;
`;

export const CardBalancesRowTitleContainer = styled('span', { label: 'CardBalancesRowTitleContainer' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
`;

export const CardBalancesRowTitle = withConfig(theme => styled('span', { label: 'CardBalancesRowTitle' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3333;
    display: flex;
    align-items: center;
`);

export const CardBalancesRowText = withConfig(theme => styled('span', { label: 'CardBalancesRowText' })`
    color: ${theme.star.accountSummary.txtColorTertiary};
    display: grid;
    gap: 4px;
    grid-template-columns: auto 1fr;
    align-items: center;
    height: 20px;
`);

export const CardBalancesRowValue = withConfig(theme => styled('span', { label: 'CardBalancesRowValue' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 20px;
    display: flex;
    align-items: center;
`);

export const ChevronRight = styled(ChevronIcon, { label: 'ChevronRight' })`
    width: 12px;
    height: 12px;
    cursor: pointer;
`;

export const GiftIconStyled = styled(GiftIcon, { label: 'GiftIconStyled' })`
    width: 16px;
    height: 16px;
`;

export const RewardIconStyled = styled(RewardIcon, { label: 'RewardIconStyled' })`
    width: 16px;
    height: 16px;
`;

export const RowLink = styled(Link, { label: 'RowLink' })`
    color: inherit;
    text-decoration: none;
    width: 100%;
`;
