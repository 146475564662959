import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface GiftIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const GiftIcon = observer('HelpIcon', ({ className, onClick }: GiftIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            width={16}
            height={16}
            fill='none'
            onClick={onClick}
        >
            <g
                fill='currentcolor'
                clipPath='url(#a)'
            >
                <path d='M1.333 5.451v3.53c0 .235.157.392.393.392h4.313V4.667H2.118a.787.787 0 0 0-.784.784Zm.785 5.294v3.137c0 .432.353.785.784.785H6.04v-4.314H2.51c-.235 0-.392.157-.392.392Zm11.764-.392h-3.529v4.314h3.137a.787.787 0 0 0 .785-.785v-3.137c0-.235-.157-.392-.393-.392Zm.393-5.686h-3.922v4.706h4.314c.235 0 .392-.157.392-.393V5.451a.787.787 0 0 0-.784-.784ZM7.02 14.667h2.353v-10H7.02v10ZM7.686 2.94c0-.863-.705-1.608-1.607-1.608H3.883c-.197 0-.275.196-.157.353l.706.706a.19.19 0 0 1 0 .275l-.706.666c-.118.157-.04.353.157.353h3.843v-.745h-.04Zm4.275-.274a.19.19 0 0 1 0-.275l.706-.706c.117-.157.04-.353-.157-.353h-2.235c-.863 0-1.608.706-1.608 1.608v.745h3.843c.157 0 .274-.196.157-.353l-.706-.666Z' />
            </g>
            <defs>
                <clipPath id='a'>
                    <path
                        fill='#fff'
                        d='M1.333 1.333H15.06v13.333H1.335z'
                    />
                </clipPath>
            </defs>
        </SvgElement>
    );
});
