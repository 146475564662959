import { Common } from 'src/domains/common/Common';
import { ResourceCustomer } from './UsersState';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { ApiGetBonusBalanceResponseType } from 'src/api_openapi/v2/bonus-engine/apiGetBonusBalance';
import { ApiGetBonusWinningsResponseType } from 'src/api_openapi/v2/bonus-engine/apiGetBonusWinnings';
import { computed } from 'mobx';
import { Amount } from 'src_common/common/amount/Amount';
import { ApiGetCustomerBonusesResponseType } from 'src/api_openapi/v2/bonus-engine/apiGetCustomerBonuses';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BasicDataModel } from './BasicDataModel';

export enum BonusBalanceRewardType {
    FREEBET = 'free-bet',
    FREESPIN = 'free-spin',
}

export class BonusBalanceModel {
    public readonly bonusBalances: ResourceCustomer<ApiGetBonusBalanceResponseType>;
    public readonly bonusWinningsBalances: ResourceCustomer<ApiGetBonusWinningsResponseType>;
    public readonly customerBonuses: ResourceCustomer<ApiGetCustomerBonusesResponseType>;

    public readonly configComponents: ConfigComponents;
    public static get = AutoWeakMap.create((common: Common) => new BonusBalanceModel(common));

    private constructor(private readonly common: Common) {
        this.bonusBalances = new ResourceCustomer(
            'bonusBalance',
            common.session,
            async (): Promise<ApiGetBonusBalanceResponseType> => {
                return await this.common.trpcClient.client.bonusEngineRouter.getBonusBalance.query();
            }
        );

        this.bonusWinningsBalances = new ResourceCustomer(
            'bonusWinningsBalances',
            common.session,
            async (): Promise<ApiGetBonusWinningsResponseType> => {
                return await this.common.trpcClient.client.bonusEngineRouter.getBonusWinnings.query();
            }
        );

        this.customerBonuses = new ResourceCustomer(
            'customerBonuses',
            common.session,
            async (): Promise<ApiGetCustomerBonusesResponseType> => {
                return await this.common.trpcClient.client.bonusEngineRouter.getCustomerBonuses.query();
            }
        );

        this.configComponents = ConfigComponents.get(this.common);
    }

    @computed public get bonusBalanceSum(): Amount {
        const balances = this.bonusWinningsBalances.valueReady;

        if (balances === null) {
            return new Amount('0');
        }

        if (balances.status === 200) {
            return balances.body.reduce((acc, curr) => {
                const omitStatuses = ['expired', 'canceled', 'claimed'];
                return omitStatuses.includes(curr.status)
                    ? acc
                    : acc.add(this.configComponents.precision.newFromPragmaticNumber(curr.balance));
            }, new Amount('0'));
        }

        return new Amount('0');
    }

    @computed public get processedBonusBalances(): { freeSpins: number; freeBets: string } {
        const bonuseBalance = this.bonusBalances.valueReady?.status === 200 ? this.bonusBalances.valueReady.body : null;

        return {
            freeSpins: bonuseBalance?.[BonusBalanceRewardType.FREESPIN]?.availableAmount ?? 0,
            freeBets: BasicDataModel.get(this.common).money(
                this.configComponents.precision.newFromOld(
                    bonuseBalance?.[BonusBalanceRewardType.FREEBET]?.availableAmount ?? 0
                )
            ),
        };
    }
}
