import * as React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { getTranslationsWithParams, LanguageTokenType, splitTranslation } from './LanguagesUtils';
import { isEmptyString } from 'src/domains/layouts/state/languagesState/isEmptyString';
import { DateTime } from 'src_common/utils/time/time';
import { getCookie, setCookie } from 'src/domains/layouts/config/config';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import {
    convertRecordToMap,
    convertRecordToMapDefault,
    convertMapToRecord,
    convertMapToRecordDefault,
} from 'src/appState/utils';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { calculateTranslationParameters } from 'src_common/common/translations/translations';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';
import { Value } from 'src_common/common/mobx-utils/Value';

export interface LangDetailsMapType {
    description: string;
    properties: Map<string, string>;
}

interface LangDetailsRecordType {
    description: string;
    properties: Record<string, string>;
}
interface LanguageItemType {
    id: string;
    description: string;
}

const exportLanguagesItem = (langDetails: LangDetailsMapType): LangDetailsRecordType => ({
    description: langDetails.description,
    properties: convertMapToRecordDefault(langDetails.properties),
});

export const exportLanguages = (
    allTranslations: Map<string, LangDetailsMapType>
): Record<string, LangDetailsRecordType> => convertMapToRecord(allTranslations, exportLanguagesItem);

const importLanguagesItem = (langDetails: LangDetailsRecordType): LangDetailsMapType => ({
    description: langDetails.description,
    properties: convertRecordToMapDefault(langDetails.properties),
});

export const importLanguages = (
    allTranslations: Record<string, LangDetailsRecordType>
): Map<string, LangDetailsMapType> => convertRecordToMap(allTranslations, importLanguagesItem);

export class LanguagesState {
    private language: Value<string | null>;

    @observable public translationsDisplayDebug: 'normal' | 'show-dot' | 'show-id';
    public static get = AutoWeakMap.create((common: Common) => new LanguagesState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);

        this.language = new Value<string | null>(null, (setValue) => {
            const timer = setInterval((): void => {
                const value = getCookie('selected-language');
                setValue(value);
            }, 1000);

            return (): void => {
                clearInterval(timer);
            };
        });

        this.translationsDisplayDebug = 'normal';

        if (this.common.envVariables.userLang !== null) {
            DateTime.current().locale(this.common.envVariables.userLang);
        }
    }

    public allTranslationsExport(): Record<string, LangDetailsRecordType> {
        return exportLanguages(this.common.envVariables.allTranslations);
    }

    @action public setUserLang = (lang: string): void => {
        setCookie('selected-language', lang, 365);
        DateTime.current().locale(lang);
    };

    @computed public get userLang(): string | null {
        const language = this.language.getValue();
        if (language !== null) {
            return language;
        }

        return this.common.envVariables.userLang;
    }

    private getTranslationInner = (
        key: string,
        defaultText: string,
        params?: Record<string, string | number>
    ): string => {
        if (this.translationsDisplayDebug === 'show-dot') {
            return '.';
        }

        if (this.translationsDisplayDebug === 'show-id') {
            return `lang-id=${key}`;
        }

        if (this.userLang !== null) {
            const langTranslations = this.common.envVariables.allTranslations.get(this.userLang);

            if (langTranslations !== undefined) {
                const translationValue = langTranslations.properties.get(key);

                if (typeof translationValue === 'string' && isEmptyString(translationValue) === false) {
                    return this.translate(translationValue, params);
                }
            }
        }

        return this.translate(defaultText, params);
    };

    public getTranslation = (key: string, defaultText: string, params?: Record<string, string | number>): string => {
        return this.getTranslationInner(key, defaultText, params);
    };

    /**
     * @deprecated - This method is only for playwright pls dont use in app
     */
    public get allTranslationsJSON(): Record<string, { description: string; properties: Record<string, string> }> {
        const first: Record<string, { description: string; properties: Record<string, string> }> = {};
        for (const [key, value] of this.common.envVariables.allTranslations) {
            const newProps: Record<string, string> = {};
            for (const [keyInner, valueInner] of value.properties) {
                newProps[keyInner] = valueInner;
            }
            const newValue: { description: string; properties: Record<string, string> } = {
                description: value.description,
                properties: newProps,
            };
            first[key] = newValue;
        }

        return first;
    }

    public getMarketTranslationName = (marketModel: MarketModel): string => {
        const marketModelName = marketModel.name.trim();

        const participants = marketModel.eventModel?.participants;
        if (participants === undefined) {
            return marketModelName;
        }
        const res = calculateTranslationParameters(marketModel.name, participants, 'market');
        if (res === null) {
            return marketModelName;
        }
        const translation = this.getTranslationInner(res[0], marketModelName);

        const translationWithParameters = this.insertParameters(translation, res[1]);

        return translationWithParameters;
    };

    public getSelectionTranslationName = (selectionModel: SelectionModel): string => {
        const participants = selectionModel.getMarket()?.eventModel?.participants;
        if (participants === undefined) {
            return selectionModel.name;
        }
        const res = calculateTranslationParameters(selectionModel.name, participants, 'selection');
        if (res === null) {
            return selectionModel.name;
        }
        const translation = this.getTranslationInner(res[0], selectionModel.name);
        const translationWithParameters = this.insertParameters(translation, res[1]);

        return translationWithParameters;
    };

    public insertParameters = (translation: string, params: Array<Record<string, string>>): string => {
        let translationToReplace = translation;
        for (const param of params) {
            for (const [key, value] of Object.entries(param)) {
                translationToReplace = translationToReplace.replaceAll(`{${key}}`, value);
            }
        }
        return translationToReplace;
    };

    public getSportTranslationName = (sportId: string, sportName: string): string => {
        const key = `sport.name.${sportId}`;
        const tempTranslation = this.getTranslationInner(key, sportName);

        return tempTranslation;
    };

    public showMarketTranslation = (marketId: MarketId | null, defaultTranslate: string): string => {
        if (marketId === null) {
            return defaultTranslate;
        }

        const marketModel = marketId.getModel();

        if (marketModel === null) {
            return defaultTranslate;
        }

        const marketNameToDisplay =
            marketModel.name.trim() === 'Each Way Extra Market'
                ? this.getTranslation('_market.extra places', 'Extra Places')
                : this.getMarketTranslationName(marketModel);
        return marketNameToDisplay;
    };

    public showSelectionTranslationName = (selectionModel: SelectionModel): string => {
        const marketModel = selectionModel.getMarket();
        const eventModel = selectionModel.getEvent();

        const participants = eventModel?.participants;
        if (participants === undefined || marketModel === null) {
            return selectionModel.name;
        }

        return this.getSelectionTranslationName(selectionModel);
    };

    public showSelectionTranslation = (
        marketId: MarketId | null,
        selectionId: number | null,
        defaultTranslate: string
    ): string => {
        if (marketId === null || selectionId === null) {
            return defaultTranslate;
        }

        const selectionModel = marketId.getSelectionId(selectionId).getModel();
        if (selectionModel === null) {
            return defaultTranslate;
        }

        return this.getSelectionTranslationName(selectionModel);
    };

    private translate(translationValue: string, params: Record<string, string | number> | undefined): string {
        if (params !== undefined) {
            return getTranslationsWithParams(translationValue, params);
        }

        return translationValue;
    }

    public getLanguages(): Array<LanguageItemType> {
        const languages: Array<LanguageItemType> = [];

        for (const [id, value] of this.common.envVariables.allTranslations.entries()) {
            if (value.properties.size > 0) {
                languages.push({
                    id,
                    description: value.description,
                });
            }
        }

        if (languages.length > 0) {
            return languages;
        }

        return [];
    }

    public translateTokens(text: string, mapFunc: (token: LanguageTokenType) => React.ReactNode): React.ReactElement {
        const messageErrorRelatedJsx = splitTranslation(text).map((item) => {
            if (typeof item === 'string') {
                return item;
            }

            return mapFunc(item);
        });

        return React.createElement(React.Fragment, null, ...messageErrorRelatedJsx);
    }

    @action public translationsDisplayDebugShowDot = (): void => {
        this.translationsDisplayDebug = 'show-dot';
    };

    @action public translationsDisplayDebugHide = (): void => {
        this.translationsDisplayDebug = 'normal';
    };

    @action public translationsDisplayDebugShowId = (): void => {
        this.translationsDisplayDebug = 'show-id';
    };
}
