import React, { ChangeEvent } from 'react';
import { InputCheckBox, CheckMarkWrapper, IconWrapper } from './BonusCheckBox.style';
import { observer } from 'src/utils/mobx-react';

interface BonusCheckboxPropsType {
    isChecked: boolean;
    handleOnChange: ((e: ChangeEvent<HTMLInputElement>) => void) | (() => void);
}

export const BonusCheckbox = observer(
    'BonusCheckbox',
    ({ isChecked, handleOnChange }: BonusCheckboxPropsType): React.ReactElement => (
        <>
            <InputCheckBox
                type='checkbox'
                onChange={handleOnChange}
                checked={isChecked}
            />

            <IconWrapper isChecked={isChecked}>
                <CheckMarkWrapper isChecked={isChecked} />
            </IconWrapper>
        </>
    )
);
