import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useAppStateContext } from 'src/appState/AppState';

export const BonusEngineFreeBetErrorMessages = observer('BonusEngineFreeBetErrorMessages', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { bonusEngineFreeBetErrors } = betSlipState.basicBetSlipState;

    return (
        <>
            {bonusEngineFreeBetErrors.map((error) => {
                switch (error.field) {
                    case 'selectedFreeBets': {
                        return (
                            <Messages
                                type='error'
                                message={
                                    <I18n
                                        langKey='betslip.free-bets.stake-exceeded-message'
                                        defaultText='Lower your stake if you want to use your bet credits.'
                                    />
                                }
                            />
                        );
                    }
                    case 'bets[].freeBets':
                        return (
                            <Messages
                                type='error'
                                message={
                                    <I18n
                                        langKey='betslip.free-bets.bonus-exceeded-message'
                                        defaultText="Added free bets can't cover all bets total stakes"
                                    />
                                }
                            />
                        );
                    default:
                        return (
                            <Messages
                                type='error'
                                message={error.code}
                            />
                        );
                }
            })}
        </>
    );
});
