import React from 'react';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { AccountSummaryState } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/AccountSummary.state';
import { observer } from 'src/utils/mobx-react';
import {
    AccountSummaryRowText,
    AccountSummaryValue,
    AccountSummaryWrapperBonuse,
    RowRecordStyleBonuses,
    RowsContainerBonuses,
    UserNameStyleBonuse,
    UserRecordStyleBonuses,
} from './AccountSummaryBonuses.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LogoutButton } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/logoutButton/LogoutButton';
import { CardBalances } from './balancesCard/BalancesCard';
import { CardRows } from './cardRows/CardRows';
import { useCommon } from 'src/domains/common/Common';

export const AccountSummaryBonuses = observer('AccountSummaryBonuses', () => {
    const common = useCommon();

    const basicDataModel = BasicDataModel.get(common);
    const basicData = basicDataModel.basicDataReady;
    const accountSummaryState = AccountSummaryState.get(common);

    if (basicData === null) {
        return null;
    }

    return (
        <AccountSummaryWrapperBonuse>
            <UserRecordStyleBonuses>
                <UserNameStyleBonuse>{basicData.name}</UserNameStyleBonuse>
                <LogoutButton />
            </UserRecordStyleBonuses>
            <RowsContainerBonuses>
                <RowRecordStyleBonuses>
                    <AccountSummaryRowText data-test='account-number-name'>
                        <I18n
                            langKey='account.summary.account-number-new'
                            defaultText='Account Number'
                        />
                    </AccountSummaryRowText>
                    <AccountSummaryValue data-test='account-number-value'>{`${common.features.accountIdPrefix}${basicData.id}`}</AccountSummaryValue>
                </RowRecordStyleBonuses>
                <RowRecordStyleBonuses>
                    <AccountSummaryRowText>
                        <I18n
                            langKey='account.summary.time-active'
                            defaultText='Time Active'
                        />
                    </AccountSummaryRowText>
                    <AccountSummaryValue>{accountSummaryState.loggedCounter}</AccountSummaryValue>
                </RowRecordStyleBonuses>
            </RowsContainerBonuses>
            <RowsContainerBonuses>
                <CardBalances accountSummaryState={accountSummaryState} />
                <CardRows />
            </RowsContainerBonuses>
        </AccountSummaryWrapperBonuse>
    );
});
