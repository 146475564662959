import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { observer } from 'src/utils/mobx-react';
import { Checkbox } from 'src/domains/sportsbook/betting/ui/checkbox/CheckBox';
import { PriceInput } from 'src/domains/sportsbook/betting/ui/betSlip/priceInput/PriceInput';
import { EventScore } from 'src/domains/sportsbook/betting/ui/betSlip/EventScore';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { ErrorMessageDeprecated, InfoMessageDeprecated } from 'src/domains/layouts/webview/components/Messages';
import { LifeSpanButton } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/SelectionLifeSpanButton';
import { BetSlipSelectionState } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.state';
import { Amount } from 'src_common/common/amount/Amount';
import { DateTime } from 'src_common/utils/time/time';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { LegItem } from 'src/domains/sportsbook/betting/betSlipState/LegItem';
import {
    ReturnsWrapper,
    ReturnValue,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipCombination/BetslipCombination.style';
import {
    EachWayBox,
    SelectionOptions,
    BetSlipSelectionWrapper,
    EventTitle,
    MarketInfoWrapper,
    BetSlipError,
    ReturnWrapper,
    EachWayBoxLabel,
    InfoName,
    TrashIconWrapper,
    TitleWrapper,
    RelatedWarningIconWrapper,
    EventName,
    NameSeparator,
    BogIndicatorSelectionList,
    BonusButton,
    AppliedFreeBetContainer,
} from './BetslipSelection.style';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RewardWalletIcon } from 'src/domains/layouts/shared/assets/iconography/betslip/RewardWallet';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const renderPotentialReturn = (
    legModel: LegItem,
    money: (amount: Amount | null | undefined, withoutZeros?: boolean) => string,
    betTooHighValue: Amount | null
): JSX.Element | null => {
    const { priceType, returnsWithBoost, stakePerLine } = legModel;
    const isSP = priceType === 'sp';
    const returnWithBoost = returnsWithBoost === null ? new Amount('0') : returnsWithBoost;
    const showReturnValue =
        stakePerLine !== null && stakePerLine.isEqualWith(new Amount('0'))
            ? money(new Amount('0'))
            : money(returnWithBoost);
    const isStake = stakePerLine !== null && stakePerLine.isGreaterThanZero();

    return (
        <ReturnsWrapper>
            <ReturnWrapper data-test='returns'>
                <I18n
                    langKey='betslip.returns.label'
                    defaultText='Returns:'
                />
                <ReturnValue>{isSP ? 'n/a' : showReturnValue}</ReturnValue>
            </ReturnWrapper>
            {betTooHighValue !== null && isStake ? (
                <ReturnWrapper>
                    <I18n
                        langKey='betslip.max-bet.label'
                        defaultText='Max. bet:'
                    />

                    <ReturnValue>{money(betTooHighValue)}</ReturnValue>
                </ReturnWrapper>
            ) : null}
        </ReturnsWrapper>
    );
};

const getEventDetails = (eventModel: EventModel, eventsCollection: EventsCollectionState): string => {
    const { timeSettingsStartTime } = eventModel;

    const competitionModel = eventsCollection.listOfSportAndCompetition('greyhoundracing', eventModel.competition);
    const competitionForView =
        competitionModel.competitionForView === null ? null : competitionModel.competitionForView[0];

    return `${competitionForView?.name ?? ''} ${DateTime.from(timeSettingsStartTime)?.format('DD MMM YYYY') ?? ''}`;
};

interface BetslipSelectionPropsType {
    id: BetslipSingleId;
    isHidden?: boolean;
}

export const BetslipSelection = observer('BetslipSelection', ({ id, isHidden }: BetslipSelectionPropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const common = useCommon();
    const { basicData, money } = BasicDataModel.get(common);
    const { languagesState, lifeSpanState } = appLayoutsState;
    const { lifeSpanSocketState } = lifeSpanState;
    const selectionWithBoost = lifeSpanSocketState.selectionsWithAvailableBoosts(id.toOldIdForLifespan);
    const { betSlipState, eventsCollection } = appSportsBookState;
    const { isShowQuickBet } = betSlipState.basicBetSlipState;
    const { isReferred } = betSlipState.referralState;
    const { betTooHighValue } = betSlipState.betSlipSummaryState;

    const [localState] = useState(() => new BetSlipSelectionState(id, betSlipState));

    const legModel = id.getModel();

    const {
        handleChangeEachWay,
        selectionId,
        isSelectedSP,
        related,
        eachWay,
        errors,
        selectionChanged,
        returnsWithBoost,
        selectedPriceType,
        stakePerLine,
        freeBets,
    } = legModel;

    const marketModel = legModel.getMarketModel();
    const eventModel = legModel.getEventModel();
    const selectionModel = legModel.getSelectionModel();
    const isExtraPlacesMarket =
        typeof selectionModel?.templateMarketId === 'string' &&
        selectionModel.templateMarketId.includes('each-way-extra'); //TODO: replace the name with extra-places when its changed on the backend side
    const bogValue = basicData.valueReady?.bpEnabled ?? false;
    const displayBOG = marketModel !== null && marketModel.bp && bogValue && !isSelectedSP;
    const appliedBonusFreeBetAmount = betSlipState.getSelectionAppliedBonuses(selectionId.id.rawId);

    const isStakeHigherThanBonusFreeBetAmount =
        common.features.bonuseEngine &&
        appliedBonusFreeBetAmount !== null &&
        stakePerLine !== null &&
        stakePerLine.isGreaterThan(appliedBonusFreeBetAmount);

    const renderCloseIcon = (): JSX.Element | null => {
        const removeSelection = (): void => {
            localState.handleRemove(lifeSpanState, selectionWithBoost);
        };

        if (isReferred) {
            return null;
        }
        return <TrashIconWrapper onClick={removeSelection} />;
    };

    if (marketModel !== null && eventModel !== null && selectionModel !== null) {
        const isStarted = eventModel.timeSettingsStarted;

        const eventName =
            marketModel.name === 'Trap Challenge' ? getEventDetails(eventModel, eventsCollection) : eventModel.name;
        const marketNameToDisplay =
            marketModel.name.trim() === 'Each Way Extra Market'
                ? languagesState.getTranslation('_market.extra places', 'Extra Places')
                : languagesState.getMarketTranslationName(marketModel);
        return (
            <BetSlipSelectionWrapper
                ref={isShowQuickBet ? null : betSlipState.betslipData.setRef(id)}
                manyBets={betSlipState.legsIds2.length > 1}
                isHidden={isHidden}
            >
                <TitleWrapper>
                    <EventTitle>
                        {displayBOG ? (
                            <BogIndicatorSelectionList size='small'>
                                <I18n
                                    langKey='events.bog'
                                    defaultText='BOG'
                                />
                            </BogIndicatorSelectionList>
                        ) : null}
                        {related === true ? <RelatedWarningIconWrapper /> : null}

                        <EventName data-test='selection-name'>
                            {languagesState.getSelectionTranslationName(selectionModel)}
                        </EventName>

                        {eventModel.timeMatchStartedAndFinished ? <EventScore eventId={eventModel.id2} /> : null}
                    </EventTitle>
                    {!isShowQuickBet && renderCloseIcon()}
                </TitleWrapper>

                <MarketInfoWrapper>
                    <InfoName data-test='singles-market-name'>{marketNameToDisplay}</InfoName>
                    <NameSeparator>|</NameSeparator>
                    <InfoName data-test='singles-event-name'>{eventName}</InfoName>
                </MarketInfoWrapper>

                <SelectionOptions>
                    <StakeInputMobx
                        selectionId={id}
                        stakePerLine={stakePerLine}
                    />
                    <PriceInput selectionId={id} />
                    {marketModel.eachWay !== undefined &&
                    marketModel.eachWay.offered &&
                    selectionModel.name.toLowerCase() !== 'unnamed favourite' &&
                    eachWay !== null ? (
                        <EachWayBox data-test='ew-checkbox'>
                            <Checkbox
                                label={
                                    <EachWayBoxLabel>
                                        <I18n
                                            langKey='betslip.selection.each-way.label'
                                            defaultText='E/W'
                                        />
                                    </EachWayBoxLabel>
                                }
                                isChecked={eachWay}
                                isDisabled={isReferred || isExtraPlacesMarket}
                                handleOnChange={handleChangeEachWay}
                            />
                        </EachWayBox>
                    ) : null}
                    {common.features.bonuseEngine && freeBets !== null && freeBets.length > 0 && (
                        <BonusButton
                            size='medium'
                            data-test='combination-bonus-button'
                            onClick={(): void => betSlipState.openBonusPopup(selectionId.id.rawId, freeBets)}
                        >
                            <RewardWalletIcon />
                        </BonusButton>
                    )}
                </SelectionOptions>

                {common.features.bonuseEngine && appliedBonusFreeBetAmount !== null && (
                    <AppliedFreeBetContainer>
                        <RewardWalletIcon /> {money(appliedBonusFreeBetAmount)} Free Bet Applied
                    </AppliedFreeBetContainer>
                )}

                {isStakeHigherThanBonusFreeBetAmount && (
                    <Messages
                        type='error'
                        message={
                            <I18n
                                langKey='betslip.free-bets.stake-exceeded-message'
                                defaultText='Lower your stake if you want to use your bet credits.'
                            />
                        }
                    />
                )}

                {renderPotentialReturn(legModel, money, betTooHighValue)}

                {errors.length > 0 ? (
                    <BetSlipError>
                        {errors.map((error): JSX.Element | null => {
                            if (!selectionChanged && ['increased', 'decreased'].includes(error.code)) {
                                return null;
                            }
                            if (error.code === 'unavailable') {
                                // TODO: check if code 'unavailable' exists - if not, find how to show 'Selection is no longer avaiable' error info
                                return (
                                    <ErrorMessageDeprecated
                                        message={error}
                                        key={`${error.code}-${error.resource}`}
                                    />
                                );
                            }
                            return (
                                <InfoMessageDeprecated
                                    message={error}
                                    hasIcon={false}
                                    key={`${error.code}-${error.resource}`}
                                />
                            );
                        })}
                    </BetSlipError>
                ) : null}

                {selectionWithBoost === null ? null : (
                    <LifeSpanButton
                        isStarted={isStarted}
                        lifeSpanState={lifeSpanState}
                        selectionWithBoost={selectionWithBoost}
                        selectionId={selectionId.id}
                        legModelReturns={returnsWithBoost}
                        selectedPriceType={selectedPriceType}
                        stakePerLine={stakePerLine}
                    />
                )}
            </BetSlipSelectionWrapper>
        );
    }
    return null;
});
