import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface RewardWalletIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const RewardWalletIcon = observer(
    'RewardWalletIcon',
    ({ className, onClick }: RewardWalletIconPropsType): JSX.Element => (
        <SvgElement
            className={className}
            onClick={onClick}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='#020D16'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_3188_18217)'>
                <path
                    d='M2 8.17647V13.4706C2 13.8235 2.23529 14.0588 2.58824 14.0588H9.05882V7H3.17647C2.52941 7 2 7.52941 2 8.17647ZM3.17647 16.1176V20.8235C3.17647 21.4706 3.70588 22 4.35294 22H9.05882V15.5294H3.76471C3.41176 15.5294 3.17647 15.7647 3.17647 16.1176ZM20.8235 15.5294H15.5294V22H20.2353C20.8824 22 21.4118 21.4706 21.4118 20.8235V16.1176C21.4118 15.7647 21.1765 15.5294 20.8235 15.5294ZM21.4118 7H15.5294V14.0588H22C22.3529 14.0588 22.5882 13.8235 22.5882 13.4706V8.17647C22.5882 7.52941 22.0588 7 21.4118 7Z'
                    fill='fill'
                />
                <path
                    d='M10.5294 22H14.0588V7H10.5294V22ZM11.5294 4.41176C11.5294 3.11765 10.4706 2 9.11764 2H5.82352C5.5294 2 5.41175 2.29412 5.58822 2.52941L6.64705 3.58824C6.76469 3.70588 6.76469 3.88235 6.64705 4L5.58822 5C5.41175 5.23529 5.5294 5.52941 5.82352 5.52941H11.5882V4.41176H11.5294ZM17.9412 4C17.8235 3.88235 17.8235 3.70588 17.9412 3.58824L19 2.52941C19.1765 2.29412 19.0588 2 18.7647 2H15.4118C14.1176 2 13 3.05882 13 4.41176V5.52941H18.7647C19 5.52941 19.1765 5.23529 19 5L17.9412 4Z'
                    fill='fill'
                />
            </g>
            <defs>
                <clipPath id='clip0_3188_18217'>
                    <rect
                        width='20.5882'
                        height='20'
                        fill='white'
                        transform='translate(2 2)'
                    />
                </clipPath>
            </defs>
        </SvgElement>
    )
);
