import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { Amount as AmountClass } from 'src_common/common/amount/Amount';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LogInToPlaceBetBtnWrapper,
    PlaceButtonWrapper,
    AcceptChangesBtnWrapper,
    FreeBetButtonWrapper,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import {
    Amount,
    AmountWrapper,
    BetNowWrapper,
    BulbContainer,
    BulbHeader,
    BulbInfoMessage,
    BulbWrapper,
    ButtonsWrapper,
    ButtonWrapper,
    EnterStakeButtonSpan,
    FreeBetIconWrapper,
    HelpIconWrapper,
    InfoIconWrapper,
    MessageInfo,
    MoreInfo,
    PayoutRulesWrapper,
    SpanBottom,
    SpanTop,
    WarningIconWrapper,
} from './BetSlipButtons.style';

export const LogInToPlaceBet = observer('LogInToPlaceBet', () => {
    const common = useCommon();
    const starRouter = StarRouter.get(common);

    return (
        <ButtonWrapper>
            <LogInToPlaceBetBtnWrapper
                isButton={true}
                size='large'
                routeNameForGtm='signup'
                key='Account:not-logged'
                onClickCallback={(): void => {
                    starRouter.redirectToLogin();
                }}
                to={{ account: 'login', promo: null }}
                dataTest='log-in-to-place-bet-button'
            >
                <I18n
                    langKey='betslip.login-button'
                    defaultText='Log in to place bet'
                />
            </LogInToPlaceBetBtnWrapper>
        </ButtonWrapper>
    );
});

export const AcceptChanges = observer('AcceptChanges', () => {
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;

    return (
        <ButtonWrapper>
            <AcceptChangesBtnWrapper
                key='Accept:change'
                onClick={betSlipState.onAcceptChanges}
                size='large'
                version='secondary-dark'
            >
                <I18n
                    langKey='betslip.accept-changes-button'
                    defaultText='Accept Price Changes'
                />
            </AcceptChangesBtnWrapper>
        </ButtonWrapper>
    );
});

export const AcceptOfferBtn = observer('AcceptOfferBtn', () => {
    const common = useCommon();
    const { money } = BasicDataModel.get(common);
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { referralState, possibleBetsRequestState, betSlipSummaryState } = betSlipState;
    const { totalStake } = betSlipSummaryState;

    return (
        <ButtonWrapper data-test='accept-and-place-bet-button'>
            <PlaceButtonWrapper
                disabled={possibleBetsRequestState.isLoading || referralState.isloading}
                isLoading={referralState.isloading}
                onClick={referralState.isloading === false ? referralState.onAcceptOffer : undefined}
                size='large'
            >
                <span>
                    <I18n
                        langKey='betslip.offer.accept-and-place-button'
                        defaultText='Accept & place bets'
                    />
                </span>
                &nbsp;
                <span>{money(totalStake)}</span>
            </PlaceButtonWrapper>
        </ButtonWrapper>
    );
});

const PlaceButton = observer('PlaceButton', () => {
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { referralState, possibleBetsRequestState, betSlipSummaryState, basicBetSlipState, selectedBonuses } =
        betSlipState;
    const { isShowQuickBet } = basicBetSlipState;
    const { isLegsChangedWarning, isPlayableBalanceWarning, isPlacingBet, totalStake } = betSlipSummaryState;
    const common = useCommon();
    const { money } = BasicDataModel.get(common);

    const showFreeBetButton = betSlipState.freeBetsAmount !== null && betSlipState.freeBetsAmount.isGreaterThanZero();
    const areSuspendedBets = betSlipState.rabState.suspendedBets.length > 0;
    const areBetsWithoutOffer = betSlipState.rabState.areBetsWithoutOffer;

    const onPlaceBets = async (): Promise<void> => {
        if (common.features.bonuseEngine) {
            return await betSlipSummaryState.onPlaceBet(false, selectedBonuses);
        } else {
            return await betSlipSummaryState.onPlaceBet(false);
        }
    };

    const isStake = totalStake.isGreaterThan(new AmountClass('0'));

    const isDisabled =
        (common.features.bonuseEngine && possibleBetsRequestState.bonusEngineFreeBetErrors.length > 0) ||
        possibleBetsRequestState.isLoading ||
        isLegsChangedWarning ||
        isPlayableBalanceWarning ||
        //isSinglesOnlyWarning
        areSuspendedBets ||
        areBetsWithoutOffer ||
        referralState.isReferred;

    const betNowButton = (
        <BetNowWrapper>
            <I18n
                langKey='betslip.summary.betNow'
                defaultText='bet now'
            />
        </BetNowWrapper>
    );

    const betButton = isStake ? (
        <>
            <SpanTop>
                <I18n
                    langKey='betslip.place-bet'
                    defaultText='Place Bet'
                />
            </SpanTop>
            <SpanBottom>{money(totalStake)}</SpanBottom>
        </>
    ) : (
        <EnterStakeButtonSpan isFreeBetBtn={showFreeBetButton}>
            <I18n
                langKey='betslip.enter-stake'
                defaultText='Please Enter a Stake'
            />
        </EnterStakeButtonSpan>
    );

    return (
        <PlaceButtonWrapper
            disabled={isDisabled || !isStake}
            onClick={onPlaceBets}
            isLoading={(isPlacingBet || possibleBetsRequestState.isLoading) && !referralState.isBetSlipOffer}
            size='large'
            dataTest='place bets'
        >
            {isShowQuickBet && !showFreeBetButton ? betNowButton : betButton}
        </PlaceButtonWrapper>
    );
});

const PlaceFreeBetButton = observer('PlaceFreeBetButton', () => {
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { referralState, possibleBetsRequestState, betSlipSummaryState, castBetsState, shouldFreebetBeAllowed } =
        betSlipState;
    const { isLegsChangedWarning, isPlayableBalanceWarning, isPlacingBet } = betSlipSummaryState;
    const areSuspendedBets = betSlipState.rabState.suspendedBets.length > 0;
    const areBetsWithoutOffer = betSlipState.rabState.areBetsWithoutOffer;

    return (
        <FreeBetButtonWrapper
            disabled={
                possibleBetsRequestState.isLoading ||
                isLegsChangedWarning ||
                (isPlayableBalanceWarning && castBetsState.isFreeBetExceedsAmount === true) ||
                // isSinglesOnlyWarning ||
                castBetsState.isFreeBetExceedsAmount ||
                shouldFreebetBeAllowed ||
                castBetsState.minOddsWarning ||
                areSuspendedBets ||
                areBetsWithoutOffer
            }
            onClick={(): Promise<void> => betSlipSummaryState.handleFreeBetsPlace(true)}
            isLoading={(isPlacingBet || possibleBetsRequestState.isLoading) && !referralState.isBetSlipOffer}
            dataTest='free bets'
            size='large'
        >
            <SpanTop>
                <I18n
                    langKey='betslip.use-bet-credits-button'
                    defaultText='Use bet Credits'
                />
            </SpanTop>

            <SpanBottom>
                {castBetsState.isFreeBetExceedsAmount ? (
                    <I18n
                        langKey='betslip.use-bet-credits-button.na'
                        defaultText='N/A'
                    />
                ) : (
                    <I18n
                        langKey='betslip.use-bet-credits-button.free'
                        defaultText='Free!'
                    />
                )}
            </SpanBottom>
        </FreeBetButtonWrapper>
    );
});

export const PlaceButtons = observer('PlaceButtons', () => {
    const { appLayoutsState, appSportsBookState, common } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { lifeSpanState } = appLayoutsState;

    const showFreeBetButton =
        !common.features.bonuseEngine &&
        betSlipState.freeBetsAmount !== null &&
        betSlipState.freeBetsAmount.isGreaterThanZero() &&
        lifeSpanState.lifeSpanSocketState.rewardValue.isEqualWithZero();

    return (
        <ButtonWrapper>
            <ButtonsWrapper hasTwoButtons={showFreeBetButton}>
                <PlaceButton />
                {showFreeBetButton ? <PlaceFreeBetButton /> : null}
            </ButtonsWrapper>
        </ButtonWrapper>
    );
});

export const PayoutRules = observer('PayoutRules', () => {
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { basicBetSlipState } = betSlipState;

    const common = useCommon();
    const starRouter = StarRouter.get(common);
    const { config } = ConfigComponents.get(common);
    const { isAuthorized: isUserAuthorized } = common.session;

    const handleRedirect = (): void => {
        starRouter.redirectToTermsAndConditions();
    };
    return config.payoutRules && isUserAuthorized ? (
        <PayoutRulesWrapper isQuickBet={basicBetSlipState.isShowQuickBet}>
            <I18n
                langKey='betslip.payout-rules-info'
                defaultText='Payout limits apply to all betting transactions.'
            />
            {/* eslint-disable-next-line */}
            <MoreInfo onClick={handleRedirect}>
                &nbsp;
                <I18n
                    langKey='betslip.payout-rules-info.more'
                    defaultText='More Info'
                />{' '}
            </MoreInfo>
        </PayoutRulesWrapper>
    ) : null;
});

export const BulbMessage = observer('BulbMessage', () => {
    const { appSportsBookState } = useAppStateContext();
    const common = useCommon();
    const starRouter = StarRouter.get(common);
    const { money } = BasicDataModel.get(common);
    const { betSlipState } = appSportsBookState;
    const { freeBetsAmount, castBetsState, shouldFreebetBeAllowed, referralState } = betSlipState;

    if (castBetsState.minOdds !== 'n/a') {
        const minOdds = parseFloat(castBetsState.minOdds);
        castBetsState.setMinOddsValue(minOdds);
    }

    return !referralState.isReferred &&
        !betSlipState.referralState.isBetSlipOffer &&
        !betSlipState.betSlipSummaryState.isLegsChangedWarning ? (
        <BulbWrapper>
            <BulbContainer isQuickBet={betSlipState.basicBetSlipState.isShowQuickBet}>
                <BulbHeader>
                    <AmountWrapper>
                        <FreeBetIconWrapper />
                        <Amount>
                            {money(freeBetsAmount)}{' '}
                            <I18n
                                langKey='account.summary.free-bet-credits.label'
                                defaultText='Free Bet Credits'
                            />
                        </Amount>
                    </AmountWrapper>
                    <HelpIconWrapper onClick={starRouter.redirectToFreeBetCreditsInfo} />
                </BulbHeader>
                {shouldFreebetBeAllowed || castBetsState.minOddsWarning ? (
                    <BulbInfoMessage>
                        {castBetsState.minOddsWarning ? <InfoIconWrapper /> : <WarningIconWrapper />}
                        <MessageInfo>
                            {castBetsState.shouldDisplayMinOdds === true && castBetsState.minOddsValue !== undefined ? (
                                <I18n
                                    langKey='betslip.free-bets.minimum-odds-message'
                                    defaultText='Minimum Odds on free bets are {minOdds}'
                                    params={{ minOdds: castBetsState.minOddsValue }}
                                />
                            ) : (
                                <I18n
                                    langKey='betslip.free-bets.stake-exceeded-message'
                                    defaultText='Lower your stake if you want to use your bet credits.'
                                />
                            )}
                        </MessageInfo>
                    </BulbInfoMessage>
                ) : (
                    void 0
                )}
            </BulbContainer>
        </BulbWrapper>
    ) : null;
});
