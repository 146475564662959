import { computed, makeObservable } from 'mobx';
import { RealityCheckState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/realityCheck/RealityCheck.state';
import { SignupRollingNetDepositLimitState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit.state';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUpParams';

export class ResponsibleGamblingState {
    public readonly realityCheckState: RealityCheckState;
    public readonly rollingNetDepositLimitState: SignupRollingNetDepositLimitState;

    public constructor(
        private readonly params: SignUpParamsType,
        private readonly redirectToNextStep: () => void
    ) {
        makeObservable(this);

        this.realityCheckState = new RealityCheckState(this.params.accountState, this.params.language);
        this.rollingNetDepositLimitState = new SignupRollingNetDepositLimitState(
            this.params.common.trpcClient,
            this.params.config,
            this.params.accountState.usersState
        );
    }

    @computed public get isButtonDisabled(): boolean {
        if (this.realityCheckState.isSwitched) {
            if (!this.realityCheckState.selectedDuration.isSelected) {
                return true;
            }
        }
        if (
            this.params.config.config.rollingNetDepositLimit === true &&
            this.rollingNetDepositLimitState.isErrorInput
        ) {
            return true;
        }
        return false;
    }

    public onSubmitForm = async (): Promise<void> => {
        if (this.realityCheckState.isSwitched) {
            await this.realityCheckState.onSubmitRealityCheckForm();
        }
        if (this.params.config.config.rollingNetDepositLimit === true) {
            await this.rollingNetDepositLimitState.onSubmit();
        }
    };

    public onMoveToNextStep = (
        responsibleGambling: string,
        isDepositLimit?: boolean,
        isRealityCheck?: boolean
    ): void => {
        this.params.customKeyboard.scrollAsideToTop();
        this.redirectToNextStep();
        this.params.googleTagManager.gtmSignUpStepThree(responsibleGambling, isDepositLimit, isRealityCheck);
    };

    public onSubmitAndNext = async (): Promise<void> => {
        try {
            await this.onSubmitForm();
            this.onMoveToNextStep(
                'activated',
                this.rollingNetDepositLimitState.setAtLeastOneLimit(),
                this.realityCheckState.isSwitched
            );
        } catch (e) {
            console.error('deposit limit and reality check error:', e);
        }
    };
}
