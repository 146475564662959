import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Card } from 'src/domains/players/webview/components/Account/common/card/Card';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    CardsBalancesRowsContainer,
    CardBalancesRowTitleContainer,
    CardBalancesRowTitle,
    ChevronRight,
    CardBalancesRowText,
    RowLink,
    GiftIconStyled,
    RewardIconStyled,
    CardBalancesRowValue,
} from './CardRows.style';
import { BonusBalanceModel } from 'src/domains/players/state/BonusBalanceModel';
import { useCommon } from 'src/domains/common/Common';

export const CardRows = observer('CardRows', () => {
    const common = useCommon();
    const bonuseBalanceModel = BonusBalanceModel.get(common);

    React.useEffect(() => {
        bonuseBalanceModel.bonusBalances.refresh();
    }, []);

    return (
        <CardsBalancesRowsContainer>
            <RowLink
                to={{
                    account: 'credits',
                    type: 'free-bets',
                }}
            >
                <Card>
                    <CardBalancesRowTitleContainer>
                        <CardBalancesRowTitle>
                            <I18n
                                langKey='account.summary.free-bets'
                                defaultText='Free Bets'
                            />
                        </CardBalancesRowTitle>
                        <ChevronRight position='right' />
                    </CardBalancesRowTitleContainer>
                    <CardBalancesRowText>
                        <GiftIconStyled />
                        <CardBalancesRowValue>
                            {bonuseBalanceModel.processedBonusBalances.freeBets}
                        </CardBalancesRowValue>
                    </CardBalancesRowText>
                </Card>
            </RowLink>
            <RowLink
                to={{
                    account: 'credits',
                    type: 'free-spins',
                }}
            >
                <Card>
                    <CardBalancesRowTitleContainer>
                        <CardBalancesRowTitle>
                            <I18n
                                langKey='account.summary.free-spins'
                                defaultText='Free Spins'
                            />
                        </CardBalancesRowTitle>
                        <ChevronRight position='right' />
                    </CardBalancesRowTitleContainer>
                    <CardBalancesRowText>
                        <RewardIconStyled />
                        <CardBalancesRowValue>
                            {bonuseBalanceModel.processedBonusBalances.freeSpins}
                        </CardBalancesRowValue>
                    </CardBalancesRowText>
                </Card>
            </RowLink>
        </CardsBalancesRowsContainer>
    );
});
