import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { WeatherState } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/weatherDetails/WeatherDetailsState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { distance as formatDistance } from 'src/domains/sportsbook/utils/format';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    RaceHeaderListEwTerms,
    RaceHeaderListItem,
    RaceHeaderListItemMeta,
    RaceHeaderListItemMetaHandicap,
    RaceHeaderListMeta,
} from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.style';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { TabKeyType } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails';
import { assertNever } from 'src_common/common/assertNever';

interface MetaPropsType {
    activeFilterId: TabKeyType | null;
    race: EventModel;
    eventId: EventId;
    isFinishedRace: boolean;
    shouldShowBogForMarket?: boolean;
}

const marketTherms = (market: MarketModel): React.ReactNode => {
    if (market.eachWayOffered) {
        const termsPlaces = market.eachWayTermsPlaces;
        const reduction = market.eachWayTermsReduction;

        if (market.templateId.includes('place-betting')) {
            return (
                <RaceHeaderListEwTerms>
                    <RaceHeaderListItem>
                        <I18n
                            langKey='events.racecard.each-way-terms.places'
                            defaultText='{places} Places'
                            params={{ places: termsPlaces ?? '' }}
                        />
                    </RaceHeaderListItem>
                </RaceHeaderListEwTerms>
            );
        }
        if (termsPlaces !== undefined && reduction !== undefined) {
            return (
                <RaceHeaderListEwTerms data-test='race-header-item-meta-ew-terms'>
                    <RaceHeaderListItem>
                        <I18n
                            langKey='events.racecard.each-way-terms.label'
                            defaultText='EW:'
                        />
                    </RaceHeaderListItem>

                    <RaceHeaderListItem>{reduction}</RaceHeaderListItem>

                    <RaceHeaderListItem>
                        <I18n
                            langKey='events.racecard.each-way-terms.for'
                            defaultText='for'
                        />
                    </RaceHeaderListItem>

                    <RaceHeaderListItem>
                        <I18n
                            langKey='events.racecard.each-way-terms.places'
                            defaultText='{places} Places'
                            params={{ places: termsPlaces }}
                        />
                    </RaceHeaderListItem>
                </RaceHeaderListEwTerms>
            );
        }
    }
};
interface RenderThermsPropsType {
    race: EventModel;
    activeFilterId: TabKeyType | null;
}

export const RenderTherms = observer('RenderTherms', (props: RenderThermsPropsType) => {
    const { race, activeFilterId } = props;
    const bettingWithoutMarket = race.marketWithout.at(0);
    const extraPlacesMarket = race.marketExtraPlaces.at(0);
    const eachWayRaceWinnerMarket = race.marketRaceWinner.at(0);
    const isExtraPlaces = extraPlacesMarket?.eachWayOffered === true;
    const isBettingWithout = bettingWithoutMarket?.eachWayOffered === true;
    const isEachWayRaceWinner = eachWayRaceWinnerMarket?.eachWayOffered === true;

    switch (activeFilterId) {
        case 'win-only':
        case 'match-bets':
        case 'sub-race':
        case 'trap-challenge':
            return null;
        case 'extra-places':
            if (isExtraPlaces) {
                return <> {marketTherms(extraPlacesMarket)}</>;
            }
            break;
        case 'betting-without':
            if (isBettingWithout) {
                return <> {marketTherms(bettingWithoutMarket)}</>;
            }
            break;
        case null:
        case 'Win or Ew':
        case 'forecast-tricast':
            if (isEachWayRaceWinner) {
                return <> {marketTherms(eachWayRaceWinnerMarket)}</>;
            }
            break;
        default:
            return assertNever('unhandled active filter id', activeFilterId);
    }
    return null;
});
type RaceWeatherMetaPropsType = Pick<MetaPropsType, 'eventId' | 'isFinishedRace'>;

const RaceWeatherMeta = observer('RaceWeatherMeta', (props: RaceWeatherMetaPropsType) => {
    const { eventId, isFinishedRace } = props;
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, sdkCustomer } = appLayoutsState;
    const { config } = configComponents;

    const [weatherStateFactory] = React.useState(
        () =>
            new MobxMapAutoNew<EventId, WeatherState>((eventId) => {
                return new WeatherState(sdkCustomer.session, eventId);
            })
    );

    const weatherState = weatherStateFactory.get(eventId);

    const weatherDetails = weatherState.getWeatherDetails;
    const hideMeta =
        config.weatherData === false || weatherState.is_active === false || weatherDetails === null || isFinishedRace;

    if (hideMeta) {
        return null;
    }

    return (
        <>
            <RaceHeaderListItemMeta>
                At race start time:&nbsp;{weatherDetails.forecastTemperature}°C
            </RaceHeaderListItemMeta>
            <RaceHeaderListItemMeta>{weatherDetails.forecastWeatherText}</RaceHeaderListItemMeta>
        </>
    );
});

type RaceInfoMetaPropsType = Pick<MetaPropsType, 'activeFilterId' | 'race' | 'shouldShowBogForMarket'>;

const RaceInfoMeta = observer('RaceInfoMeta', (props: RaceInfoMetaPropsType) => {
    const { race, activeFilterId } = props;
    const goingInfo = race.feedDataGoing;
    const handicapCond = race.feedHandicap === 'Y';
    const courseType = race.feedCourseType;
    const distance = formatDistance(race.feedDistance);

    return (
        <>
            {handicapCond && race.sportOriginal !== 'internationalhorseracing' ? (
                <RaceHeaderListItemMetaHandicap data-test='race-header-item-meta-handicap'>
                    <I18n
                        langKey='events.handicap.label'
                        defaultText='Handicap'
                    />
                </RaceHeaderListItemMetaHandicap>
            ) : null}
            {courseType === undefined ? null : (
                <RaceHeaderListItemMeta
                    isCourseType
                    data-test='race-header-item-meta-course-type'
                >
                    {courseType}
                </RaceHeaderListItemMeta>
            )}
            {distance === '' ? null : (
                <RaceHeaderListItemMeta data-test='race-header-item-meta-distance'>{distance}</RaceHeaderListItemMeta>
            )}
            {goingInfo === undefined ? null : (
                <RaceHeaderListItemMeta data-test='race-header-item-meta-going-info'>
                    {goingInfo}
                </RaceHeaderListItemMeta>
            )}
            <RaceHeaderListItemMeta>
                <RenderTherms
                    race={race}
                    activeFilterId={activeFilterId}
                />
            </RaceHeaderListItemMeta>
        </>
    );
});

export const Meta = observer('Meta', (props: MetaPropsType) => {
    const { race, eventId, isFinishedRace, shouldShowBogForMarket, activeFilterId } = props;

    return (
        <RaceHeaderListMeta>
            <RaceWeatherMeta
                eventId={eventId}
                isFinishedRace={isFinishedRace}
            />
            <RaceInfoMeta
                activeFilterId={activeFilterId}
                race={race}
                shouldShowBogForMarket={shouldShowBogForMarket}
            />
        </RaceHeaderListMeta>
    );
});
