import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { withConfig } from 'src/withConfig';

interface BonusCheckBoxType {
    isChecked: boolean;
}

export const InputCheckBox = styled('input', { label: 'InputCheckBox' })`
    display: none;
`;

const checkBoxWrapperColors = (theme: EmotionTheme, isChecked: boolean):SerializedStyles => {

    if (isChecked === true) {
        return css`
            background: ${theme.star.popup.txtColorQuaternary};
            border-color: ${theme.star.popup.txtColorQuaternary};
        `;
    }

    return css`
        background: transparent;
        border-color: ${theme.star.checkbox.light.borderColor};
    `;
};


export const IconWrapper = withConfig(theme => styled('span', { label: 'IconWrapper' })<BonusCheckBoxType>`
    ${({ isChecked }): SerializedStyles => checkBoxWrapperColors(theme, isChecked)};
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex: 0 0 24px;
    height: 24px;
    justify-content: center;
    width: 24px;
`);

interface CheckMarkWrapperType {
    isChecked: boolean;
}

export const CheckMarkWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'CheckMarkWrapper' })<CheckMarkWrapperType>`
    ${({ isChecked }): string => isChecked ? 'transform: scale(1)' : 'transform: scale(0)'};
    fill: ${theme.star.popup.txtColorSecondary};
    transition: transform .2s ease;
    width: 16px;
`);
