import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { ConfigType, withConfig } from "src/withConfig";

export type BadgeType = 'primary' | 'secondary' | 'success' | 'warning' | 'error'

export interface BadgeStyleType {
    type: BadgeType;
    clickable: boolean;
}

export const buildBadgeColors = (type: BadgeType, theme: ConfigType, clickable: boolean): SerializedStyles => {
     if (type === 'primary') {
        return css`
            border: 1px solid ${theme.star.badge.borderColor};
            background-color: ${theme.star.badge.bgColor};
            color: ${theme.star.badge.txtColor};
            ${clickable && `
                cursor: pointer;
                &:hover {
                    border: 1px solid ${theme.star.badge.borderColorSecondary};
                }
            `}
        `;
    }

    if (type === 'secondary') {
        return css`
            border: 1px solid ${theme.star.badge.bgColorSecondary};
            background-color: ${theme.star.badge.bgColorSecondary};
            color: ${theme.star.badge.txtColorSecondary};
        `;
    }

    if (type === 'success') {
        return css`
            border: 1px solid ${theme.star.statuses.success.bgColor};
            background-color: ${theme.star.statuses.success.bgColor};
            color: ${theme.star.statuses.success.txtColor};
        `;
    }

    if (type === 'warning') {
        return css`
            border: 1px solid ${theme.star.statuses.warning.bgColor};
            background-color: ${theme.star.statuses.warning.bgColor};
            color: ${theme.star.statuses.warning.txtColor};
        `;
    }

    return css`
        border: 1px solid ${theme.star.statuses.error.bgColor};
        background-color: ${theme.star.statuses.error.bgColor};
        color: ${theme.star.statuses.error.txtColor};
    `;
};

export const BadgeStyled = withConfig(theme => styled('div', { label: 'BadgeStyled' }) <BadgeStyleType>`
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    border-radius: 100px;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xSmall};
    cursor: default;
    user-select: none;

    ${({ type, clickable }): SerializedStyles | null => buildBadgeColors(type, theme, clickable)};
`);
