import styled from "@emotion/styled";
import { withConfig } from "src/withConfig";


export const CardStyled = withConfig(theme => styled('div', { label: 'CardStyled' })<{haveBorder: boolean}>`
    padding: 16px;
    width: 100%;
    border-radius: 4px;
    background-color: ${theme.star.card.bgColor};
    color: ${theme.star.card.txtColor};
    border: ${({ haveBorder }): string => haveBorder ? `1px solid ${theme.star.card.borderColor}`: ''};
`);
