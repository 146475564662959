import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BadgeGroupStyled, BadgeDropdownContainer, BadgeDropdownContent, BadgeDropdownInner } from './BadgeGroup.style';
import { Badge } from './Badge';

interface BadgeGroupPropsType {
    badges: Array<string | number | boolean>;
}

//If 3 show 3 | if > 3 ? 2 and +x
export const BadgeGroup = observer('BadgeGroup', ({ badges }: BadgeGroupPropsType) => {
    const badgesToShowLength = badges.length > 2 ? 2 : length;

    return (
        <BadgeGroupStyled>
            {[...badges.slice(0, badgesToShowLength)].map((text) => (
                <Badge key={typeof text === 'boolean' ? null : text}>{text}</Badge>
            ))}
            {badges.length > 2 ? (
                <BadgeDropdownContainer>
                    <Badge clickable={true}>+{badges.length - badgesToShowLength}</Badge>
                    <BadgeDropdownContent className='dropdown-content'>
                        <BadgeDropdownInner>
                            {[...badges.slice(badgesToShowLength, badges.length)].map((text) => (
                                <Badge key={typeof text === 'boolean' ? null : text}>{text}</Badge>
                            ))}
                        </BadgeDropdownInner>
                    </BadgeDropdownContent>
                </BadgeDropdownContainer>
            ) : null}
        </BadgeGroupStyled>
    );
});
