import { ErrorsType, CombinationsType } from './BetSlipTypes';
import { computed, makeObservable } from 'mobx';
import { convertToNumber } from 'src/appState/utils';
import { PriceType } from './BetSlipSheredTypes';
import { Amount } from 'src_common/common/amount/Amount';
import { StakeInputState2 } from './stakeInputState/StakeInputState2';
import { BetslipData } from './BetslipData';
import { CombinationState } from 'src/domains/sportsbook/betting/state/betSlipState/CombinationsState';
import { ReferralState } from './ReferralState';

export class CombinationItem {
    public constructor(
        private readonly combinationId: string,
        private readonly referralState: ReferralState,
        private readonly combinationState: CombinationState,
        private readonly betslipData: BetslipData
    ) {
        makeObservable(this);
    }

    public get stakeInput(): StakeInputState2 {
        return this.betslipData.getInputCombination(this.combinationId);
    }

    public handleOnChange = (data: Amount): void => {
        this.betslipData.onChangeCombinationStake(this.combinationId, data.value);
    };

    @computed public get combination(): CombinationsType | null {
        if (this.referralState.isReferred) {
            this.referralState.getCombinationById(this.combinationId);
        }

        return this.combinationState.getCombinationById(this.combinationId);
    }

    @computed public get errors(): Array<ErrorsType> {
        if (this.combination !== null) {
            const errors = this.combination.errors ?? [];
            const castTypes = ['FC', 'RFC', 'CFC', 'TC', 'CTC'];

            if (castTypes.includes(this.combination.type)) {
                return errors.filter((error) => error.code !== 'related' && error.code !== 'suspended');
            }

            const uniqErrors = new Set();
            const filteredErrors = errors.filter((item) => {
                const isDuplicate = uniqErrors.has(item.code);
                uniqErrors.add(item.code);
                return !isDuplicate;
            });

            return filteredErrors;
        }

        return [];
    }

    @computed public get id(): string {
        return this.combinationId;
    }

    @computed public get freeBets(): Array<string> | null {
        return this.combination?.freeBets ?? null;
    }

    @computed public get ewOffered(): boolean | null {
        return this.combination?.ewOffered ?? null;
    }

    @computed public get eachWay(): boolean | null {
        return this.combination?.eachWay ?? null;
    }

    @computed public get name(): string {
        return this.combination?.name ?? '';
    }

    @computed public get numLines(): number | null {
        return this.combination?.numLines ?? null;
    }

    @computed public get potentialReturns(): Amount | null {
        if (this.combination === null || this.combination.potentialReturns === null) return null;

        return new Amount(this.combination.potentialReturns);
    }

    @computed public get potentialReturnsAt(): Amount | null {
        if (this.combination === null || this.combination.potentialReturnsAt === null) return null;

        return new Amount(this.combination.potentialReturnsAt);
    }

    @computed public get specialMessageStake(): Amount | null {
        if (this.referralState.isReferred) {
            return null;
        }

        return this.combinationState.getSpecialMessageStake(this.combinationId);
    }

    @computed public get price(): PriceType | null {
        if (this.combination === null || this.combination.price === null) return null;

        return this.combination.price ?? null;
    }

    @computed public get stakePerLine(): Amount | null {
        if (this.combination === null || this.combination.stakePerLine === null) return null;

        return new Amount(this.combination.stakePerLine);
    }

    public handleChangeEachWay = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.betslipData.onChangeEachWayCombination(this.combinationId, e.currentTarget.checked);
    };

    @computed public get returns(): Amount {
        if (
            (this.potentialReturnsAt !== null && this.potentialReturnsAt === this.stakePerLine) ||
            this.price === null ||
            this.eachWay !== null
        ) {
            return this.potentialReturns ?? new Amount('0');
        }

        const price = this.price.f.split('/');
        const priceDen = convertToNumber(price[0]);
        const priceNum = convertToNumber(price[1]);

        if (priceDen !== null && priceNum !== null && this.stakePerLine !== null) {
            return this.stakePerLine.multiply(priceDen).div(priceNum).add(this.stakePerLine);
        }

        return new Amount('0');
    }
}
