import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { InfoIcon } from 'src/domains/layouts/shared/assets/iconography/interface/InfoIcon';

export const TooltipContainer = styled('div', { label: 'TooltipContainer' })`
    position: relative;

    &:hover .tooltip-content {
        visibility: visible;
    }

    &:hover .tooltip-triangle {
        visibility: visible;
    }
`;

export const IconContainer = styled('div', { label: 'IconContainer' })`
    display: flex;
    align-items: center;
`;

export const Icon = styled(InfoIcon, { label: 'Icon' })`
    height: 16px;
`;

export const TooltipTriangle = withConfig(theme => styled('div', { label: 'TooltipTriangle' })`
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    margin-left: 4px;
    margin-right: 16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    border-bottom: 15px solid ${theme.star.tooltip.bgColor};
    visibility: hidden;
`);

export const TooltipContent = withConfig(theme => styled('div', { label: 'TooltipContent' })`
    position: absolute;
    top: 35px;
    width: 343px;
    transform: translate(-150px, 0);
    background: ${theme.star.tooltip.bgColor};
    color: ${theme.star.tooltip.txtColor};
    font-size: ${theme.star.fontSize.small};
    padding: 2px;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    visibility: hidden;
    text-align: center;
`);
